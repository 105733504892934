<template>
    <div class="singleFileUploader">

        
        <div>
            <span class="textNoWrap" v-if="file.name">{{file.name }}</span>
            <a href="" class="textNoWrap" @click.prevent="openFilePicker" v-else>Upload</a>
        </div>

        <div class="fileUploadBtn">
            <button class="default small reverse round" @click="openFilePicker"><i class="fas fa-cloud-upload-alt"></i></button>
            <span v-if="error" class="error"><i class="fas fa-exclamation-triangle"></i></span>
        </div>

        <input type="file" name="file" ref="files" @change="fileSelected" hidden>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
    export default {
        props: ['modelValue', 'disabled', 'error'],
        data() {
            return {
                file: this.modelValue || {},
                isUploading: true
            }
        },
        computed: {
            ...mapGetters(['token']),
            apiURL() {
                return process.env.VUE_APP_API_URL;
            }
        },
        methods: {
            openFilePicker() {
                if(this.disabled) return;
                this.$refs['files'].click();
            },
            abortOrCancel(file, key) {
                try {
                    if(file.xmlReq) file.xmlReq.abort();
                } catch(err) {  }

                this.selectedFiles.splice(key, 1);
            },
            fileSelected() {
                if(this.disabled) return;
                let files = this.$refs['files'].files;
                this.uploadFiles(files);
            },
            uploadFiles(files) {
                
                if(this.disabled) return;

                const url = process.env.VUE_APP_API_URL + '/files/upload'; // uploading url

                let file = files[0];
                this.file = {
                    name: file.name,
                    categoryName: file.name,
                    size: (file.size / 1048576).toFixed(2),
                    status: 0,
                    path: null,
                    xmlReq: new XMLHttpRequest()
                }

                let fileItem = this.file;

                let formData = new FormData();
                formData.append("document", file);

                this.$emit('uploadStart');

                fileItem.xmlReq = new XMLHttpRequest();
                fileItem.xmlReq.upload.addEventListener("progress", (event) => {
                    var percent = Math.round((event.loaded / event.total) * 100);
                    fileItem.status = percent;
                    this.$emit('update:percent', percent);
                }, false);

                fileItem.xmlReq.addEventListener("load", (event) => {
                    let res = JSON.parse(event.target.response);
                    fileItem.status = 100;
                    fileItem.path = res.path;
                    delete fileItem.xmlReq;
                    this.$emit('uploadEnd');
                }, false);

                fileItem.xmlReq.open("POST", url);
                fileItem.xmlReq.setRequestHeader('authorization', this.token);
                fileItem.xmlReq.send(formData);
                

                this.$refs['files'].value = '';

            }
        },
        watch: {
            file: {
                deep: true,
                handler() {
                    if(this.file.status < 100) return;
                    this.$emit('update:modelValue', {
                        name: this.file.name,
                        path: this.file.path
                    });
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
.fileUploadBtn {
    display: flex;
    align-items: center;
    .error {
        margin-left: 10px;
        font-size: 12px;
        color: $error;
    }
}

.singleFileUploader {
    display: grid;
    grid-template-columns: minmax(0, 1fr) auto;
    align-items: center;
    column-gap: 10px;
}

._status {
    .bar {
        width: 75px;
        height: 10px;
        border: 1px solid #eee;
        border-radius: 5px;
        overflow: hidden;
        position: relative;
        .line {
            position: absolute;
            top: 0;
            left: 0;
            width: 0;
            height: 100%;
            background: $buttonColor;
        }
    }
}

.fileUploader {
    display: grid;
}

.header {
    padding: 0 20px;
    height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: $bg;
    border-bottom: 1px solid $borderColor;
    button {
        padding: 0;
        width: 34px;
        height: 34px;
        background: transparent;
        color: $buttonColor;
    }
}

.clickMessage {
    padding: 20px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>