<template>
    <div class="content-wrapper">
        
        <Input name="Email address" v-model="email" :error="errors.email"/>

        <div class="action">
            <button class="default medium" :disabled="isLoading(['sendDocumentViaEmail'])" @click="sendDocument">Send</button>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['options'],
        data() {
            return {
                email: this.options.email || '',
                docId: this.options.docId || null,
                loadId: this.options.loadId || null,
                errors: {}
            }
        },
        methods: {
            sendDocument() {
                this.errors = {};
                this.ajax("sendDocumentViaEmail", {
                    method: "POST",
                    url: `/dispatch/load/${this.loadId}/emailDocument/${this.docId}`,
                    data: {
                        email: this.email
                    },
                }, (err, body) => {
                    if (err) {
                       if(body.errors) this.errors = body.errors;
                       else alert(body.message || 'Something went wrong. Please, try again!')
                    } else {
                        this.$hideModalSimple();
                    }
                });
            }
        },
    }
</script>

<style lang="scss" scoped>
.content-wrapper {
    display: grid;
    gap: 20px;
}
</style>