<template>
    <div class="docBtnHolder">
        <div class="docContent">
            <div class="docName"><a class="doc" :href="`${apiURL}/storage/files/${doc._id}`" target="_blank">{{ doc.type }}</a></div>
        </div>
        <div class="menu" :class="[{ 'active' : showMenu }]" v-click-outside="() => { showMenu = false; }">
            <button class="docMenuBtn" @click="showMenu = !showMenu"><i class="fa-solid fa-ellipsis-vertical"></i></button>
            <div class="menuHolder" v-if="showMenu">
                <button @click="openDoc">Open</button>
                <button @click="$emit('sendDocument'); showMenu = false">Send via email</button>
                <button @click="removeDoc" class="remove" v-if="permitted">Remove</button>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['doc', 'permitted'],
        data() {
            return {
                showMenu: false
            }
        },
        computed: {
            apiURL() {
                return process.env.VUE_APP_API_URL;
            }
        },
        methods: {
            openDoc() {
                let url = `${this.apiURL}/storage/files/${this.doc._id}`;
                window.open(url, '_blank');
                this.showMenu = false;
            },
            removeDoc() {
                this.$emit('remove', this.doc._id);
                this.showMenu = false;
            }
        },
    }
</script>

<style lang="scss" scoped>
.docBtnHolder {
    display: grid;
    grid-template-columns: minmax(0, 1fr) auto;
    width: 100%;
    align-items: center;
    padding: 5px 0;
    border-bottom: 1px solid $borderColor;
    &:last-child {
        border: 0;
    }
}

.docMenuBtn {
    width: 28px;
    height: 28px;
    border-radius: 8px;
    border: 1px solid $borderColor;
    background: $bgSecondary;
    font-size: 14px;
    &:hover {
        cursor: pointer;
    }
}

.menu {
    position: relative;
    &.active {
        z-index: 2;
    }
}

.menuHolder {
    position: absolute;
    top: 0;
    right: 0;
    width: 150px;
    border: 1px solid $borderColor;
    background: $bgSecondary;
    border-radius: 8px;
    overflow: hidden;
    button {
        width: 100%;
        height: 34px;
        border: 0;
        border-bottom: 1px solid $borderColor;
        background: transparent;
        color: $buttonColor;
        &:last-child {
            border: 0;
        }
        &:hover {
            background: $buttonColor2;
        }
        &.remove {
            color: $error;
        }
    }
}
</style>