<template>
    <div class="quickEditContentHolder">

        <Input v-model="body.notes" name="Load notes" placeholder="Load notes" :error="errors.notes"/>

        <div class="actionButtons">
            <button class="default medium" :disabled="isLoading(['HandleQuickEdit'])" @click="handleQuickEdit">{{ isLoading(['HandleQuickEdit']) ? 'Saving...' : 'Save changes' }}</button>
        </div>

    </div>
</template>

<script>

    export default {
        props: ['options'],
        data() {
            return {
                body: this.options,
                errors: {}
            }
        },

        methods: {
            handleQuickEdit() {
                this.errors = {};
                this.ajax('HandleQuickEdit', {
                    url: `/dispatch/QuickEdit/${this.body._id}/notes`,
                    method: 'POST',
                    data: this.body
                }, (err, body) => {
                    if(err) {
                        if(body.errors) this.errors = body.errors;
                        else alert(body.message || 'Something went wrong. Please, try again!');
                        return;
                    }
                    this.$hideModalSimple({ changesMade: true });
                });
            }
        },
    }
</script>

<style lang="scss" scoped>
.quickEditContentHolder {
    display: grid;
    row-gap: 30px;
    .actionButtons {
        display: flex;
        justify-content: center;
    }
}

.rateConUpload {
    border: 1px solid $borderColor;
    padding: 20px;
    border-radius: $borderRadius;
    background: $bgSecondary;
    position: relative;
    overflow: hidden;
    .statusBar {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 4px;
        .line {
            width: 33%;
            height: 100%;
            background: $buttonColor;
        }
    }
}
</style>