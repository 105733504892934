<template>
    <div class="gridHolder">

        
        <Input v-model="item.rate" name="Rate" placeholder="Load Rate" :error="errors.rate"/>


        <div class="rowOfTwo">
            <div class="input">
                <div class="_header">
                    <label>Weight</label>
                    <span class="_error">{{ errors.weight }}</span>
                </div>
                <NumberSelector v-model="item.weight" name="lbs"/>
            </div>

            <div class="input">
                <div class="_header">
                    <label>Length</label>
                    <span class="_error">{{ errors.length }}</span>
                </div>
                <NumberSelector v-model="item.length" name="ft"/>
            </div>
        </div>

        <div class="input">
            <div class="_header">
                <label>Rate confirmation</label>
                <span class="_error">{{ errors.rateConfirmation }}</span>
            </div>
            <div class="rateConUpload">
                <SingleFilePicker v-model="item.rateConfirmation" :error="errors.rateConfirmation"/>
            </div>
        </div>
        
        
        <div class="action">
            <button class="default medium reverse"  @click="$emit('previous')">Previous</button>
            <button class="default medium" @click="validateData">Next</button>
        </div>

    </div>
</template>

<script>
import LocationSearch from '../LocationSearch.vue'
import NumberSelector from '../NumberSelector.vue'
import DatePickerSimple from '../../DatePickerSimple.vue'
import SingleFilePicker from '../../SingleFilePicker.vue'

    export default {
        components: {
            LocationSearch,
            NumberSelector,
            DatePickerSimple,
            SingleFilePicker
        },
        props: ['modelValue'],
        data() {
            return {
                item: this.modelValue || {},
                errors: {}
            }
        },
        methods: {
            validateData() {
                this.errors = {};
                this.ajax('validateLoadDetails', {
                    method: 'POST',
                    url: `/dispatch/validateLoadDetails`,
                    data: {
                        date: this.item.date,
                        rate: this.item.rate,
                        weight: this.item.weight,
                        length: this.item.length,
                        rateConfirmation: this.item.rateConfirmation
                    }
                }, (err, body) => {
                    if(err) {
                        if(body.errors) {
                            this.errors = body.errors;
                        } else {
                            alert(body.message || 'Information provided is invalid.');
                        }
                        return;
                    } 
                    this.$emit('next');
                    return;
                });
                
            }
        },
        watch: {
            modelValue: {
                handler() {
                    this.item = this.modelValue || {};
                }
            },
            item: {
                deep: true,
                handler() {
                    this.$emit('update:modelValue', this.item);
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
.gridHolder {
    display: grid;
    row-gap: 20px;
}

.error {
    color: #D8000C;
    background-color: #FFBABA;
    border-left: 2px solid #D8000C;
    padding: 10px;
    margin-bottom: 10px;
    font-size: 12px;
}

.action {
    display: flex;
    justify-content: flex-end;
    .reverse {
        margin-right: 10px;
    }
}

.rowOfTwo {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    column-gap: 20px;
}


.rateConUpload {
    border: 1px solid $borderColor;
    padding: 20px;
    background: $bgSecondary;
    border-radius: $borderRadius;
}
</style>