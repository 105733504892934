<template>
<div>
    <div class="loadDetailsTable">

        
        <div class="row">
            <div class="title">Pickup date</div>
            <div class="value" v-if="isEdit"><DatePicker2 :class="{ 'error' : errors.date }" v-model="load.date"/></div>
            <div class="value" v-else>{{ pickup }}</div>
        </div>
        
        <div class="row">
            <div class="title">Carrier</div>
            <div class="value">

                <select v-model="load.carrier" v-if="isEdit" class="invisibleInput textRight" :class="{ 'error' : errors.carrier }">
                    <option :value="null">-- Select carrier --</option>
                    <option :key="key" v-for="(item, key) of carriers" :value="item._id">{{ item.name }}</option>
                </select>

                <span v-else>{{ carrier.name }}</span>
            </div>
        </div>

        <div class="row">
            <div class="title">Driver</div>
            <div class="value"><input :disabled="!isEdit" class="invisibleInput textRight" :class="{ 'error' : errors.driver }" type="text" v-model="load.driver" placeholder="Driver"></div>
        </div>

        <div class="row">
            <div class="title">Broker</div>
            <div class="value"><AutoComplete :disabled="!isEdit" v-model="load.broker" placeholder="Broker" :inputClass="`invisibleInput textRight ${ errors.broker ? 'error' : '' }`" type="brokers"/></div>
        </div>

        <div class="row">
            <div class="title">Contact Name</div>
            <div class="value"><input :disabled="!isEdit" class="invisibleInput textRight" :class="{ 'error' : errors.contactName }" type="text" v-model="load.contactName" placeholder="Contact name"></div>
        </div>

        <div class="row">
            <div class="title">Contact Phone</div>
            <div class="value"><input :disabled="!isEdit" class="invisibleInput textRight" :class="{ 'error' : errors.contactPhone }" type="text" v-model="load.contactPhone" placeholder="Contact phone"></div>
        </div>

        <div class="row">
            <div class="title">Contact E-mail</div>
            <div class="value"><input :disabled="!isEdit" class="invisibleInput textRight" :class="{ 'error' : errors.contactEmail }" type="text" v-model="load.contactEmail" placeholder="Contact E-mail"></div>
        </div>

        <div class="row">
            <div class="title">Rate</div>
            <div class="value">
                <input v-if="isEdit" class="invisibleInput textRight" :class="{ 'error' : errors.rate }" type="text" v-model="load.rate" placeholder="Rate">
                <span v-else>${{ priceConverter(load.rate) }}</span>
            </div>
        </div>


        <div class="row">
            <div class="title">Rate Confirmation</div>
            <div class="value">
                <SingleFilePicker v-if="isEdit" v-model="load.rateConfirmation" :error="errors.rateConfirmation" :class="{ 'error' : errors.rateConfirmation }"/>
                <span class="textNoWrap" v-else-if="load.rateConfirmation && load.rateConfirmation.path"><a :href="apiURL + load.rateConfirmation.path" target="_blank">{{ load.rateConfirmation.name }}</a></span>
                <span v-else>-</span>
            </div>
        </div>

        <div class="row">
            <div class="title">Weight</div>
            <div class="value"><input :disabled="!isEdit" class="invisibleInput textRight" :class="{ 'error' : errors.weight }" type="text" v-model="load.weight" placeholder="Weight"></div>
        </div>

        

        <div class="row">
            <div class="title">Length</div>
            <div class="value"><input :disabled="!isEdit" class="invisibleInput textRight" :class="{ 'error' : errors.length }" type="text" v-model="load.length" placeholder="Length"></div>
        </div>

        <div class="row">
            <div class="title">Dispatch</div>
            <div class="value">{{ dispatcher ? dispatcher.name : '-' }}<LoadShareBtn :load="load" v-if="load._id"/></div>
        </div>

        <div class="row">
            <div class="title">Sharing with?</div>
            <div class="value">

                <select v-model="load.sharingWith" v-if="isEdit" class="invisibleInput textRight" :class="{ 'error' : errors.sharingWith }">
                    <option :value="null">-- No one --</option>
                    <option :key="key" v-for="(item, key) of dispatchersExlcudeCurrentUser" :value="item._id">{{ item.name }}</option>
                </select>

                <span v-else>{{ sharingWith ? sharingWith.name : '-' }}</span>
            </div>
        </div>

        <div class="row">
            <div class="title">Notes</div>
            <div class="value"><input :disabled="!isEdit" class="invisibleInput textRight" :class="{ 'error' : errors.notes }" type="text" v-model="load.notes" placeholder="Important load notes"></div>
        </div>

        
    </div>
</div>
</template>

<script>
import { mapGetters } from 'vuex';
import moment from 'moment';
import SingleFilePicker from '../SingleFilePicker.vue';
import LoadShareBtn from './LoadShareBtn.vue';
import AutoComplete from '../AutoComplete.vue'

    export default {
        components: {
            SingleFilePicker,
            LoadShareBtn,
            AutoComplete
        },
        props: ['load', 'isEdit', 'errors'],
        data() {
            return {
                dispatchers: [],
                sendMessageToDispatcher: false
            }
        },
        methods: {
            async getDispatchTeam() {
                this.dispatchers = await new Promise(resolve => {
                    this.$axios.get('/users/permissions/loads/add')
                    .then(data => {
                        return resolve(data.data);
                    })
                    .catch(err => {
                        return resolve([]);
                    });
                });
            }
        },
        computed: {
            ...mapGetters(['users', 'carriers', 'user']),
            apiURL() {
                return process.env.VUE_APP_API_URL;
            },
            dispatchersExlcudeCurrentUser() {
                // return this.dispatchers;
                return this.dispatchers.filter(user => user._id != this.load.dispatcher);
            },
            pickup() {
                return moment(this.load.date, 'MM/DD/YYYY').format("ddd, DD MMM, YYYY");
            },
            carrier() {
                for(const item of this.carriers) {
                    if(item._id === this.load.carrier) return item;
                }
                return null;
            },
            dispatcher() {
                for(const item of this.users) {
                    if(item._id === this.load.dispatcher) return item;
                }
                return null;
            },
            sharingWith() {
                if(!this.load.sharingWith) return null;
                for(const item of this.users) {
                    if(item._id === this.load.sharingWith) return item;
                }
                return null;
            }
        },
        mounted() {
            this.getDispatchTeam();
            this.load.notes = this.load.notes || '';
            this.load.contactName = this.load.contactName || '';
            this.load.contactEmail = this.load.contactEmail || '';
            this.load.contactPhone = this.load.contactPhone || '';
            this.load.length = this.load.length || '';
        }
    }
</script>

<style lang="scss" scoped>
.loadDetailsTable {
    .row {
        display: grid;
        height: 50px;
        align-items: center;
        padding: 0 20px;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        &:nth-child(2n) {
            background: $bg;
        }
        .title {
            font-weight: bold;
        }
        .value {
            text-align: right;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            button {
                margin-left: 10px;
            }
        }
    }
}
</style>