<template>
    <div class="gMapsNavigation">
        <div class="mapHolder" ref="mapHolder"></div>
    </div>
</template>

<script>
import { Loader } from '@googlemaps/js-api-loader';


    export default {
        props: ['stops'],
        data() {
            return {
                directionsService: null,
                directionsRenderer: null,
                map: null,
                timeout: null
            }
        },
        methods: {
            getRoute() {
                let that = this;

                this.map.setZoom(3);
                this.map.setCenter({
                    lat: 39.77746842075953, 
                    lng: -101.43786171303788,
                })
                that.directionsRenderer.setDirections({routes: []});


                let origin = null;
                let destination = null;
                let waypoints = [];
                for(let i = 0; i <= this.stops.length - 1; i++) {
                    if(i == 0) {
                        origin = this.stops[i].value.addressLine2;
                        continue;
                    }
                    if(i == (this.stops.length - 1)) {
                        destination = this.stops[i].value.addressLine2;
                        continue;
                    }

                    waypoints.push({
                        location: this.stops[i].value.addressLine2,
                        stopover: true,
                    });

                }
                
                if(!origin || !destination) {
                    return;
                }


                var request = {
                    origin: origin,
                    destination: destination,
                    waypoints: waypoints,
                    optimizeWaypoints: true,
                    travelMode: 'DRIVING'
                };


                this.directionsService.route(request, function(result, status) {
                    if (status == 'OK') {
                        that.directionsRenderer.setDirections(result);
                    }
                });

            }
        },
        watch: {
            stops() {
                this.getRoute();
            }
        },
        mounted() {

            // set loads
            const loader = new Loader({
                apiKey: "AIzaSyDLxJWw58qz7U2DCEpEW71sFvQRQH8mURQ",
                version: "weekly",
                libraries: ["directions"]
            });

            // map options
            const mapOptions = {
                center: {
                    lat: 39.77746842075953, 
                    lng: -101.43786171303788,
                },
                zoom: 3,
                disableDefaultUI: true
            };
            
            // google maps DOM holder
            let gMaps = this.$refs['mapHolder'];

            let that = this;

            // run loader
            loader
            .load()
            .then((google) => {
                that.directionsService = new google.maps.DirectionsService();
                that.directionsRenderer = new google.maps.DirectionsRenderer();
                that.map = new google.maps.Map(gMaps, mapOptions);
                that.directionsRenderer.setMap(that.map);
                that.getRoute();
            })
            .catch(e => {
                // do something
            });

        }
    }
</script>

<style lang="scss" scoped>

.gMapsNavigation {
    position: relative;
    width: 100%;
    padding-top: 50%;
    .mapHolder {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}
</style>