<template>
    <div class="locationSearchGridHolder">
        <div class="locationGroupName">
            <div class="name">{{ name }}</div>
            <div class="error">{{ error }}</div>
        </div>
        <div class="locationSearch" :class="{ 'active' : show }" >
            <div v-click-outside="hideResults">
                <div class="locationInputHolder">
                    <input class="searchInput" v-model="search" placeholder="Search" @focus="handleSearch()" @keyup="handleSearch" @keyup.enter="handleSearch"/>
                    <span class="icon"><i class="fa-solid fa-turn-up"></i></span>
                </div>

                <div class="searchResults" v-if="show && (isLoading(['searchCustomers']) || searching)">
                    <div class="centerItem"><Loader/></div>
                </div>

                <div class="searchResults" v-else-if="show && results.length > 0">
                    <div class="resultItem" :key="key" v-for="(item, key) of results" @click="selectItem(item)">
                        <strong class="resultName">{{ item.name }}</strong>
                    </div>
                </div>

                <div class="searchResults" v-else-if="show && results.length <= 0">
                    <div class="centerItem">No results</div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import Loader from '../PageLoader.vue'

    export default {
        props: ['modelValue', 'name', 'disableGrid', 'error'],
        components: {
            Loader
        },
        data() {
            return {
                search: '',
                selection: this.modelValue || {},
                results: [],
                show: false,
                timeout: null,
                searching: false
            }
        },
        computed: {
            brokerName() {
                return this.selection.name;
            }
        },
        mounted() {
            this.setSearch();
        },
        methods: {
            setSearch() {
                if(this.selection.name) {
                    this.search = this.selection.name;
                } else {
                    this.search = '';
                }
            },
            hideResults() {
                this.show = false;
                this.setSearch();
            },
            selectItem(item) {
                this.selection = item;
                this.hideResults();
            },
            async handleSearch() {
                this.show = true;
                if(this.search.length <= 0) return;
                this.results = [];

                this.searching = true;
                clearTimeout(this.timeout);
                await new Promise(resolve => {
                    this.timeout = setTimeout(resolve, 500);
                });

                this.ajax('searchCustomers', {
                    method: "GET",
                    url: `/searchRTS?name=${this.search}`
                }, (err, body) => {
                    this.searching = false;
                    if(err) {
                        this.results = [];
                        return;
                    }
                    this.results = body;
                });

                
            }
        },
        watch: {
            selection: {
                deep: true,
                handler() {
                    this.$emit('update:modelValue', this.selection);
                    this.setSearch();
                    this.$emit('change');
                }
            },
            modelValue: {
                handler() {
                    this.selection = this.modelValue || {};
                }
            },
            brokerName() {
                this.$emit('update:name', this.brokerName);
            }
        }
    }
</script>

<style lang="scss" scoped>

.locationInputHolder {
    position: relative;
    .icon {
        position: absolute;
        top: 0;
        right: 0;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 40px;
        transform: rotate(90deg);
        font-size: 12px;
        opacity: 0.7;
    }
}

.locationSearchGridHolder {
    display: grid;
    row-gap: 10px;
    .locationGroupName {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .name {
            font-weight: bold;
        }
        .error {
            color: $error;
            font-size: $sm-text;
        }
    }
}

.searchInput {
    width: 100%;
    height: 40px;
    width: 100%;
    padding: 0 10px;
    border: 1px solid $borderColor;
    border-radius: 5px;
    background: $bgSecondary;
}

.locationSearch {
    position: relative;
    z-index: 999;
    display: grid;
    row-gap: 10px;
    &.active {
        z-index: 1000;
    }
}

.searchResults {
    position: absolute;
    top: 40px;
    left: 0;
    width: 100%;
    border: 1px solid $borderColor;
    background: $bgSecondary;
    box-shadow: $boxShadow;
    min-height: 40px;
    overflow: hidden;
    max-height: 200px;
    overflow-y: scroll;
    border-radius: 5px;
    .resultItem {
        padding: 10px;
        cursor: pointer;
        .resultName {
            font-weight: bold;
            font-size: 14px;
        }
        .resultAddress {
            font-size: 12px;
            opacity: 0.7;
            margin-left: 5px;
        }
        &:hover {
            background: $borderColor;
        }
    }
}

.rowOfThree {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    column-gap: 10px;
    row-gap: 10px;
    &.rowOfOne {
        grid-template-columns: repeat(1, minmax(0, 1fr));
    }
}

.inputGroup {
    width: 100%;
    display: grid;
    row-gap: 5px;
    input {
        width: 100%;
        height: 34px;
        padding: 0 10px;
        border: 1px solid $borderColor;
        border-radius: 5px;
    }
}

.selectionInputHolder {
    display: grid;
    row-gap: 10px;
}

.centerItem {
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}
</style>