<template>
    <div>
        <div class="userNav" :class="{ 'expanded' : menuShown }" v-click-outside="hideMenu">
            
            <div class="logoHolder"><Logo/></div>

            <div class="links" :class="{ 'expanded' : menuShown }">

                <router-link to="/app" @click="hideMenu">
                    <div class="icon"><i class="fas fa-tachometer-alt"></i></div>
                    <span>Dashboard</span>
                </router-link>

                <router-link to="/app/dispatch" @click="hideMenu" v-if="isUserPermitted('loads', 'view')">
                    <div class="icon"><i class="fas fa-truck-loading"></i></div>
                    <span>Dispatch</span>
                </router-link>

                <router-link to="/app/customers" @click="hideMenu" v-if="isUserPermitted('loads', 'view')">
                    <div class="icon"><i class="fa-solid fa-building"></i></div>
                    <span>Customers</span>
                </router-link>

                <router-link to="/app/reports" @click="hideMenu" v-if="isUserPermitted('loads', 'view')">
                    <div class="icon"><i class="fas fa-dollar-sign"></i></div>
                    <span>Payroll</span>
                </router-link>
                
                <router-link to="/app/carriers" @click="hideMenu" v-if="isUserPermitted('carriers', 'view')">
                    <div class="icon"><i class="fas fa-truck-moving"></i></div>
                    <span>Carriers</span>
                </router-link>

                <router-link to="/app/invoices" @click="hideMenu" v-if="isUserPermitted('invoices', 'manage')">
                    <div class="icon"><i class="fas fa-file-invoice-dollar"></i></div>
                    <span>Invoices</span>
                </router-link>

                <router-link to="/app/perfomance" @click="hideMenu" v-if="isUserPermitted('invoices', 'manage')">
                    <div class="icon"><i class="fas fa-chart-line"></i></div>
                    <span>Performance</span>
                </router-link>

                <router-link to="/app/users" @click="hideMenu" v-if="isUserPermitted('users', 'view')">
                    <div class="icon"><i class="fas fa-user"></i></div>
                    <span>Users</span>
                </router-link>

                <router-link to="/app/teams" @click="hideMenu" v-if="isUserPermitted('teams', 'manage')">
                    <div class="icon"><i class="fas fa-users"></i></div>
                    <span>Teams</span>
                </router-link>

                <router-link to="/app/expenses" @click="hideMenu" v-if="isUserPermitted('invoices', 'manage')">
                    <div class="icon"><i class="fa-solid fa-receipt"></i></div>
                    <span>Expenses</span>
                </router-link>

                <router-link to="/app/tracking" @click="hideMenu" v-if="isUserPermitted('tracking', 'create')">
                    <div class="icon"><i class="fa-solid fa-location-arrow"></i></div>
                    <span>Fake Tracking</span>
                </router-link>

                
            </div>


            <div class="links" :class="{ 'expanded' : menuShown }">

               
                <a href="" @click.prevent="switchDarkMode">
                    <div class="icon" v-if="darkModeOn"><i class="far fa-moon"></i></div>
                    <div class="icon" v-else><i class="fas fa-moon"></i></div>
                    <span>{{ darkModeOn ? 'Light mode' : 'Dark mode' }}</span>
                </a>

                
                <a href="" @click.prevent="switchMenuMode">
                    <div class="icon" v-if="menuShown"><i class="fas fa-chevron-left"></i></div>
                    <div class="icon" v-else><i class="fas fa-chevron-right"></i></div>
                    <span>Hide</span>
                </a>

            </div>

        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import UserMenuHeader from './UserMenuHeader.vue'
import Logo from './Logo'

    export default {
        components: {
            UserMenuHeader,
            Logo
        },
        computed: {
            ...mapGetters(['user', 'navigation'])
        },
        data() {
            return {
                menuShown: false,
                darkModeOn: false
            }
        },
        methods: {
            hideMenu() { 
                this.menuShown = false;
            },
            switchMenuMode() {
                this.menuShown = !this.menuShown;
            },
            logOut() {
                localStorage.removeItem("token");
                this.$store.commit('token', null);
                this.$store.commit('user', null);
                this.$router.push({ path: '/' });
            },
            switchDarkMode() {
                this.darkModeOn = !this.darkModeOn;
            },
            checkDarkMode() {
                if(this.darkModeOn) {
                    document.body.classList.add('dark-theme');
                } else {
                    document.body.classList.remove('dark-theme');
                }
            }
        },
        mounted() {
            let darkModeOnOrOff = localStorage.getItem('app-dark-modeon');
            if(darkModeOnOrOff) {
                if(darkModeOnOrOff == 'true') this.darkModeOn = true;
                else this.darkModeOn = false;
            }
            this.checkDarkMode();
        },
        watch: {
            darkModeOn() {
                localStorage.setItem("app-dark-modeon", this.darkModeOn);
                this.checkDarkMode();
            }
        }
    }
</script>

<style lang="scss" scoped>

.logoHolder {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.userNav {
    position: fixed;
    top: 0;
    left: 0;
    width: 100px;
    height: 100%;
    background: $bg;
    border-right: 1px solid $borderColor;
    z-index: 9999;
    padding: 150px 25px 25px 25px;
    transition: ease 0.5s;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    &.expanded {
        width: 250px;
    }

    .links {
        display: grid;
        row-gap: 10px;
        width: 100%;
        a {
            display: grid;
            align-items: center;
            grid-template-columns: 50px auto;
            border-radius: $borderRadius;
            color: #909090;
            transition: ease 0.5s;
            .icon {
                width: 50px;
                height: 50px;
                display: flex;
                justify-content: center;
                align-items: center;
            }
            span {
                width: 0;
                overflow: hidden;
                transition: ease 0.5s;
                text-overflow: ellipsis;
                overflow: hidden; 
                white-space: nowrap;
                padding: 0;
            }

            &:hover, &.router-link-exact-active {
                color: $buttonColor;
                background: $bgSecondary;
                box-shadow: $boxShadow;
            }
        }

        &.expanded {
            a {
                span {
                    width: 100%;
                    padding: 0 20px;
                }
            }
        }
    }

}

</style>