<template>
    <div>
        <div class="logo logo-dark"><img src="/images/logo-dark.png"></div>
        <div class="logo logo-light"><img src="/images/logo-light.png"></div>
    </div>
</template>

<script>
    export default {
        
    }
</script>

<style lang="scss" scoped>
.logo {
    width: 100%;
    max-width: 100px;
    img {
        width: 100%;
    }
}

.logoColor {
    fill: #212529;
}

.logo-dark {
    display: block;
}

.logo-light {
    display: none;
}


.dark-theme {
    .logo-dark {
        display: none;
    }

    .logo-light {
        display: block;
    }
}
</style>