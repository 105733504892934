<template>
    <div class="datePickerGroup" v-click-outside="hideCalender">
        <button type="button" class="datePickerButton" @click="switchCalendar">{{ selectedTime }}</button>

        <div class="datePicker" v-show="show">
            <div class="scrollableSelector">
                <button :key="key" v-for="(item, key) of getDayHours" @click="selectHour(item)" :class="{ 'active' : item == selectedHour }">{{ item }} <span v-if="item == selectedHour"><i class="fas fa-check"></i></span></button>
            </div>
            <div class="scrollableSelector">
                <button :key="key" v-for="(item, key) of hourMinutes" @click="selectMinute(item)" :class="{ 'active' : item == selectedMinute }">{{ item }} <span v-if="item == selectedMinute"><i class="fas fa-check"></i></span></button>
            </div>
        </div>
    </div>
</template>

<script>
import moment from 'moment'

    export default {
        props: ['modelValue'],
        data() {
            return {
                time: null,
                show: false,
                step: 5
            }
        },
        methods: {
            switchCalendar() {
                this.show = !this.show;
            },
            hideCalender() {
                this.show = false;
            },
            verifyTime() {
                let time = moment(this.modelValue, "HH:mm");
                if(time.toString() == "Invalid date") {
                    this.time = moment({ hour: 12, minute: 0 });
                    return;
                }
                this.time = time;
            },
            selectHour(item) {
                let newTime = moment({ hour: item, minute: this.selectedMinute });
                this.time = newTime;
            },
            selectMinute(item) {
                this.time = moment({ hour: this.selectedHour, minute: item });
            }
        },
        computed: {
            selectedTime() {
                if(!this.time) this.verifyTime();
                return this.time.format("HH:mm");
            },
            selectedHour() {
                return this.time.format("HH");
            },
            selectedMinute() {
                return this.time.format("mm");
            },
            getDayHours() {
                const items = [];
                new Array(24).fill().forEach((acc, index) => {
                    items.push(moment( { hour: index } ).format('HH'));
                })
                return items;
            },
            hourMinutes() {
                const items = [];
                let step = 60 / this.step;
                new Array(step).fill().forEach((acc, index) => {
                    items.push(moment( { minute: index * this.step } ).format('mm'));
                })
                return items;
            }
        },
        mounted() {
            this.verifyTime();
        },
        watch: {
            modelValue() {
                let time = moment(this.modelValue, "HH:mm");
                this.time = time;
                this.verifyTime();
            },
            selectedTime() {
                this.$emit('update:modelValue', this.selectedTime);
            },
            time() {
                this.$emit('update:modelValue', this.selectedTime);
            }
        }
    }
</script>

<style lang="scss" scoped>
.datePickerGroup {
    position: relative;
    display: flex;
}

.datePicker {
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translate(-50%, 20px);
    width: 100%;
    width: 160px;
    height: 200px;
    z-index: 9999;
    background: $bgSecondary;
    box-shadow: $boxShadow;
    border-radius: $borderRadius;
    overflow: hidden;
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    align-content: start;
    align-items: start;
    .scrollableSelector {
        height: 100%;
        border-right: 1px solid $borderColor;
        overflow: auto;
        display: grid;
        align-content: start;
        align-items: start;
        button {
            width: 100%;
            text-align: left;
            border: 0;
            background: transparent;
            height: 34px;
            padding: 0 10px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            &.active {
                background: $buttonColor;
                color: $bg;
                font-weight: bold;
            }
        }
    }
}


.daysPicker {
    padding: 10px;
}

.header {
    display: grid;
    grid-template-columns: 60px minmax(0, 1fr) 60px;
    height: 60px;
    border-bottom: 1px solid $borderColor;
    button {
        border: 0;
        background: transparent;
    }
}



.datePickerButton {
    width: 100%;
    height: 100%;
    color: $buttonColor;
    background: transparent;
    border: 0;
    text-align: left;
    padding: 0 10px;
}
</style>