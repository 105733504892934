<template>
    <div class="loadShareBtn" v-click-outside="hideWindow">
        <button @click="switchMessageWindow" class="default share-btn">
            <i class="fas fa-bullhorn"></i>
        </button>
        <div class="messagesList" v-show="showMessageWindow">
            <button class="button" @click="sendQuickMessageNow(item)" :key="key" v-for="(item, key) of messages">{{ item }}</button>
            <button class="button" @click="shareLoadWithDispatcher">Custom message</button>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['load'],
        data() {
            return {
                messages: [
                    'Missing Rate Confirmation',
                    'Delivered?',
                    'Picked up?'
                ],
                showMessageWindow: false
            }
        },
        methods: {
            switchMessageWindow() {
                this.showMessageWindow = !this.showMessageWindow;
            },
            hideWindow() {
                this.showMessageWindow = false;
            },
            sendQuickMessageNow(message) {
                this.showMessageWindow = false;
                this.quickMessageData.body.to = this.load.dispatcher;
                this.quickMessageData.body.message = message;
                this.quickMessageData.body.attachment = {
                    load: {
                        _id: this.load._id,
                        origin: this.load.origin,
                        destination: this.load.destination
                    },
                    type: 'load'
                }
                this.sendQuickMessage();
            },
            shareLoadWithDispatcher() {
                this.quickMessageData.modal = true;
                this.quickMessageData.body.to = this.load.dispatcher;
                this.quickMessageData.body.message = '';
                this.quickMessageData.body.attachment = {
                    load: {
                        _id: this.load._id,
                        origin: this.load.origin,
                        destination: this.load.destination
                    },
                    type: 'load'
                }
            }
        },
    }
</script>

<style lang="scss" scoped>
.loadShareBtn {
    position: relative;
    margin-left: 10px;
}

.share-btn {
    width: 34px;
    height: 34px;
    padding: 0;
    font-size: 12px;
    border-radius: 12px;
}

.messagesList {
    position: absolute;
    top: 50%;
    right: 100%;
    transform: translate(-20px, -50%);
    background: $bgSecondary;
    box-shadow: $boxShadow;
    border-radius: $borderRadius;
    width: 250px;
    display: grid;
    overflow: hidden;
    border: 1px solid $borderColor;
    .button {
        width: 100%;
        height: 40px;
        border: 0;
        background: transparent;
        color: $buttonColor;
        &:hover {
            background: $bg;
        }
    }
}
</style>