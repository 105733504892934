<template>
    <div>
        <FileUploder v-model="load.documents" :disabled="!isPermittedToEdit" :isEdit="isEdit" @completed="saveLoad"/>
    </div>
</template>

<script>
import FileUploder from '../FileUploader.vue'
    export default {
        props: ['load', 'isEdit'],
        components: {
            FileUploder
        },
        data() {
            return {
                
            }
        },
        methods: {
            saveLoad() {
                this.$emit('save');
            }
        },
        computed: {
            isPermittedToEdit() {
                if(this.user._id == this.load.dispatcher && this.isUserPermitted("loads", "edit") || this.isUserPermitted("loads", "editAll")) return true;
                return false;
            },
        },
        mounted() {
            if(!this.load.documents) this.load.documents = [];
        }
    }
</script>

<style lang="scss" scoped>

</style>