<template>
    <div class="gMapsNavigation">
        <div class="mapHolder" ref="mapHolder"></div>
    </div>
</template>

<script>
import { Loader } from '@googlemaps/js-api-loader';


    export default {
        props: ['origin', 'destination'],
        data() {
            return {
                directionsService: null,
                directionsRenderer: null,
                map: null,
                timeout: null
            }
        },
        methods: {
            getRoute() {
                let that = this;

                this.map.setZoom(3);
                this.map.setCenter({
                    lat: 39.77746842075953, 
                    lng: -101.43786171303788,
                })
                that.directionsRenderer.setDirections({routes: []});
                if(!this.origin && !this.destination) {
                    return;
                }

                clearTimeout(this.timeout);
                this.timeout = setTimeout(() => {

                    var request = {
                        origin: this.origin,
                        destination: this.destination,
                        travelMode: 'DRIVING'
                    };


                    this.directionsService.route(request, function(result, status) {
                        if (status == 'OK') {
                            that.directionsRenderer.setDirections(result);
                        }
                    });

                }, 300);
                
            }
        },
        watch: {
            origin() {
                this.getRoute();
            },
            destination() {
                this.getRoute();
            }
        },
        mounted() {

            // set loads
            const loader = new Loader({
                apiKey: "AIzaSyDLxJWw58qz7U2DCEpEW71sFvQRQH8mURQ",
                version: "weekly",
                libraries: ["directions"]
            });

            // map options
            const mapOptions = {
                center: {
                    lat: 39.77746842075953, 
                    lng: -101.43786171303788,
                },
                zoom: 3,
                disableDefaultUI: true
            };
            
            // google maps DOM holder
            let gMaps = this.$refs['mapHolder'];

            let that = this;

            // run loader
            loader
            .load()
            .then((google) => {
                that.directionsService = new google.maps.DirectionsService();
                that.directionsRenderer = new google.maps.DirectionsRenderer();
                that.map = new google.maps.Map(gMaps, mapOptions);
                that.directionsRenderer.setMap(that.map);
                that.getRoute();
            })
            .catch(e => {
                // do something
            });

        }
    }
</script>

<style lang="scss" scoped>

.gMapsNavigation {
    position: relative;
    width: 100%;
    padding-top: 50%;
    .mapHolder {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}
</style>