<template>
    <div class="quickEditContentHolder">

        <div class="input">
            <div class="_header">
                <label>Rate confirmation</label>
                <span class="_error">{{ errors.rateConfirmation }}</span>
            </div>
            <div class="rateConUpload">
                <div class="statusBar" v-if="isUploading">
                    <div class="line" :style="`width: ${uploadingPercent}%`"></div>
                </div>
                <SingleFilePicker v-model="body.rateConfirmation" :error="errors.rateConfirmation" v-model:percent="uploadingPercent" @uploadStart="isUploading = true" @uploadEnd="isUploading = false; percent = 0;"/>
            </div>
        </div>

        <div class="actionButtons">
            <button class="default medium" :disabled="isLoading(['HandleQuickEdit'])" @click="handleQuickEdit">{{ isLoading(['HandleQuickEdit']) ? 'Saving...' : 'Save changes' }}</button>
        </div>

    </div>
</template>

<script>
import SingleFilePicker from '../../../SingleFilePicker.vue'

    export default {
        components: {
            SingleFilePicker
        },
        props: ['options'],
        data() {
            return {
                body: this.options,
                errors: {},
                isUploading: false,
                uploadingPercent: 0
            }
        },

        methods: {
            handleQuickEdit() {
                this.errors = {};
                this.ajax('HandleQuickEdit', {
                    url: `/dispatch/QuickEdit/${this.body._id}/validateRateConfirmation`,
                    method: 'POST',
                    data: this.body
                }, (err, body) => {
                    if(err) {
                        if(body.errors) this.errors = body.errors;
                        else alert(body.message || 'Something went wrong. Please, try again!');
                        return;
                    }
                    this.$hideModalSimple({ changesMade: true });
                });
            }
        },
    }
</script>

<style lang="scss" scoped>
.quickEditContentHolder {
    display: grid;
    row-gap: 30px;
    .actionButtons {
        display: flex;
        justify-content: center;
    }
}

.rateConUpload {
    border: 1px solid $borderColor;
    padding: 20px;
    border-radius: $borderRadius;
    background: $bgSecondary;
    position: relative;
    overflow: hidden;
    .statusBar {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 4px;
        .line {
            width: 33%;
            height: 100%;
            background: $buttonColor;
        }
    }
}
</style>