<template>
    <div class="loadGroup" v-if="load._id">
        
        <div class="loadGroupContent">

            <div class="_header">
                <h1 class="title">Load #{{ load.loadID }}</h1>
                <span v-if="isPermittedToEdit">
                    <a href="" @click.prevent="startEdit" v-if="!isEdit">Edit</a>
                    <a href="" class="default small round deleteBtn" @click.prevent="removeLoad" v-else>Delete</a>
                </span>
            </div>



            <LocationSearch name="Origin" v-model="load.pickup" v-model:cityState="load.origin"/>
            <LocationSearch name="Destination" v-model="load.delivery" v-model:cityState="load.destination"/>

            <div class="sections">
                <div class="categories">
                    <button class="default small round" 
                    :key="key" v-for="(item, key) of sections" 
                    :class="{ 'active' : key == selectedSection }"
                    @click="selectedSection = key">{{ item.name }}</button>
                </div>
                <div class="componentContent">
                    <component :isEdit="isEdit" @save="saveLoad" :errors="errors" v-bind:is="sections[selectedSection].component" :load="load"></component>
                </div>
            </div>


            <div class="saveAction" v-if="isEdit">
                <button class="default reverse" @click.prevent="cancelChanges" :disabled="processing">Cancel</button>
                <button class="default" @click.prevent="saveLoad" :disabled="processing">Save changes</button>
            </div>

        </div>

    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import moment from 'moment';
import LoadDetails from './LoadDetails'
import LoadDocuments from './LoadDocuments'
import LocationSearch from './LocationSearch.vue';

    export default {
        props: ['loadDetails', 'loadID'],
        components: {
            LoadDetails,
            LoadDocuments,
            LocationSearch
        },
        data() {
            return {
                sections: [
                    { name: 'Details', component: 'LoadDetails' },
                    { name: 'Documents', component: 'LoadDocuments' },
                ],
                selectedSection: 0,
                isEdit: false,
                errors: {},
                processing: false,
                load: this.loadDetails || {},
                loadCopy: null
            }
        },
        mounted() {
            this.getLoadDetails();
        },
        computed: {
            ...mapGetters(['users', 'carriers']),
            pickup() {
                return moment(this.load.date, 'YYYY-MM-DD').format("DD MMM, YYYY");
            },
            isPermittedToEdit() {
                if(this.user._id == this.load.dispatcher && this.isUserPermitted("loads", "edit") || this.isUserPermitted("loads", "editAll")) return true;
                return false;
            },
        },
        methods: {
            getLoadDetails() {
                if(!this.loadID) return;
                this.$axios.get(`/dispatch/load/${this.loadID}`)
                .then(data => {
                    this.load = data.data;
                })
                .catch(err => {
                    
                });
            },
            removeLoad() {
                this.$emit('remove', this.load._id);
            },
            cancelChanges() {
                this.isEdit = false;
                this.load = JSON.parse(JSON.stringify(this.loadCopy));
            },
            startEdit() {
                this.isEdit = true;
                this.loadCopy = JSON.parse(JSON.stringify(this.load));
            },
            async saveLoad() {
                let body = {...this.load};
                delete body._id;

                this.processing = true;
                this.errors = {};

                await this.$axios.put(`/dispatch/load/${this.load._id}`, body)
                .then(data => {
                    this.isEdit = false;
                })
                .catch(err => {
                    let response = err.response;

                    if(response && response.data) {
                        let body = response.data;
                        if(body.errors) this.errors = body.errors;
                        else if(body.message) alert(body.message);
                        else alert("Something went wrong. Please, try again!");
                    } else {
                        alert("Something went wrong. Please, try again!");
                    }

                });
                this.processing = false;
            }
        },
        watch: {
            loadID() {
                this.getLoadDetails();
            }
        }
    }
</script>

<style lang="scss" scoped>

.loadGroupContent {
    display: grid;
    row-gap: 30px;
}

.saveAction {
    display: grid;
    grid-template-columns: 150px minmax(0, 1fr);
    column-gap: 20px;
}

.originDestination {
    display: grid;
    row-gap: 10px;
    .origin, .destination {
        font-size: 20px;
        display: flex;
        align-items: center;
        column-gap: 10px;
        width: 100%;
    }
    .destination {
        font-weight: bold;
        width: 100%;
        display: grid;
        grid-template-columns: 40px minmax(0, 1fr);
        align-items: center;
        align-content: center;
    }
    ._icon {
        font-size: 14px;
        transform: rotate(90deg);
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.date {
    color: rgb(172, 172, 172);
}

.sections {
    display: grid;
    row-gap: 20px;
    .categories {
        display: flex;
        column-gap: 10px;
        button {
            border: 1px solid $buttonColor;
            background: $bg;
            color: $buttonColor;
            &.active {
                background: $buttonColor;
                color: $buttonText;
            }
        }
    }
}

.componentContent {
    border: 1px solid $borderColor;
    border-radius: $borderRadius;
    background: $bgSecondary;
}

._header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 40px;
}

.deleteBtn {
    background: $error;
}
</style>