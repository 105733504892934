<template>
    <div class="docsGrid">

        <div class="listOfDocs" v-if="docs.length > 0">
            <LoadFileItem :permitted="permitted" :key="doc._id" v-for="doc of docs" :doc="doc" @remove="deleteDocument(doc._id)" @sendDocument="sendDocumentViaEmail(doc._id)"/>
        </div>
        <div v-else>No documents found.</div>

        <div class="uploadBtn" v-if="permitted" v-tooltip="'Upload document'">
            <select v-model="newDoc.item.type">
                <option :value="type" :key="key" v-for="(type, key) of types">{{ type }}</option>
            </select>
            <button class="selectedDoc textNoWrap" @click="openFilePicker">{{ newDoc.item.name }}</button>
            <button class="upload" @click="uploadFile"><i class="fa-solid fa-upload"></i></button>
            <input type="file" ref="file" @change="fileSelected" hidden>
        </div>

    </div>
</template>

<script>
import LoadFileItem from './LoadFileItem.vue';
import SendDocumentViaEmailVue from './SendDocumentViaEmail.vue';

    export default {
    components: { LoadFileItem, SendDocumentViaEmailVue },
    props: ["documents", "load", "permitted"],
    data() {
        return {
            docs: this.documents || [],
            types: ["BOL", "POD", "Other"],
            newDoc: {
                item: {
                    type: "BOL",
                    name: "Select file"
                }
            }
        };
    },
    computed: {
        apiURL() {
            return process.env.VUE_APP_API_URL;
        }
    },
    methods: {
        sendDocumentViaEmail(docId) {
            this.$showModalSimple(SendDocumentViaEmailVue, 'Send Document via Email', {
                docId: docId,
                loadId: this.load._id,
                email: this.load.contactEmail || ''
            }, (data) => {});
        },
        openFilePicker() {
            this.$refs["file"].click();
        },
        fileSelected() {
            try {
                let file = this.$refs["file"].files[0];
                this.newDoc.item.name = file.name;
            }
            catch (e) {
            }
        },
        uploadFile() {
            var formData = new FormData();
            let file = this.$refs["file"].files[0];
            formData.append("file", file);
            this.ajax("uploadLoadDocument", {
                method: "POST",
                url: `/dispatch/load/${this.load._id}/uploadDocument/${this.newDoc.item.type}`,
                data: formData,
            }, (err, body) => {
                if (!err) {
                    this.$emit("fileUploaded");
                    this.$refs["file"].value = "";
                    this.newDoc.item.name = "Select file";
                }
            });
        },
        deleteDocument(id) {
            this.ajax("uploadLoadDocument", {
                method: "DELETE",
                url: `/dispatch/load/documents/${this.load._id}/${id}`,
            }, (err, body) => {
                if (!err) {
                    this.$emit("fileUploaded");
                }
                else {
                    alert(body.message || "Something went wrong. Please, try again!");
                }
            });
        }
    },
    watch: {
        documents() {
            this.docs = this.documents || [];
        }
    }
}
</script>

<style lang="scss" scoped>

.docsGrid {
    display: grid;
    row-gap: 20px;
    padding: 15px;
    border: 2px dashed $borderColor;
    border-radius: 10px;
}

.uploadBtn {
    width: 100%;
    display: grid;
    grid-template-columns: 100px minmax(0, 1fr) 50px;
    height: 50px;
    border-radius: $borderRadius;
    box-shadow: $boxShadow;
    background: $bgSecondary;
    border: 1px solid $borderColor;
    select {
        border: 0;
        background: transparent;
        padding: 0 10px;
        font-weight: bold;
        color: $buttonColor;
    }
    button {
        border: 0;
        background: transparent;
        color: $text;
    }
    .upload {
        border-left: 1px solid $borderColor;
        &:hover {
            color: $buttonColor;
        }
    }
    .selectedDoc {
        border-left: 1px solid $borderColor;
        padding: 0 20px;
    }
}

</style>