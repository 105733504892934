<template>
    <div class="gridHolder">
        <BrokerSearch name="Broker" v-model="item.brokerDetails" @change="getBrokerContactDetails" v-model:name="item.broker"/>

        <div class="input" v-if="brokerContactList.length > 0">
            <div class="_header">
                <label>Broker contact</label>
                <span class="_error"></span>
            </div>
            <select v-model="brokerContactKey">
                <option :value="null">-- Select contact --</option>
                <option :key="key" :value="key" v-for="(contact, key) of brokerContactList">{{ contact.name }}{{ contact.phone ? ' - ' + contact.phone : '' }}{{ contact.email ? ' - ' + contact.email : '' }}</option>
            </select>
        </div>

        <div class="gridHolder">
            <Input v-model="item.contactName" name="Contact name" placeholder="Contact name" :error="errors.contactName"/>
            <Input v-model="item.contactPhone" name="Contact phone" placeholder="Contact phone" :error="errors.contactPhone" validate="phone"/>
            <Input v-model="item.contactEmail" name="Contact email" placeholder="Contact email" :error="errors.contactEmail"/>
        </div>

        <div class="input">
            <div class="_header">
                <label>Carrier</label>
                <span class="_error">{{ errors.carrier }}</span>
            </div>
            <select v-model="item.carrier">
                <option :value="undefined">-- Select carrier --</option>
                <option :value="carrier._id" :key="carrier._id" v-for="carrier of carriers">{{ carrier.name }}</option>
            </select>
        </div>

        <div class="input">
            <div class="_header">
                <label>Driver</label>
                <span class="_error">{{ errors.driver || errors.carrierDriver }}</span>
            </div>
            <select v-model="item.carrierDriver">
                <option :value="null">-- Select driver --</option>
                <option :key="driver._id" :value="driver._id" v-for="driver of carrierDrivers">{{ driver.name }} {{ driver.lastname }} - {{ driver.truck && driver.truck[0] ? driver.truck[0].name : 'No Truck Assigned' }}</option>
            </select>
        </div>


        <div class="input">
            <div class="_header">
                <label>Sharing this load?</label>
                <span class="_error">{{ errors.sharingWith }}</span>
            </div>
            <select v-model="item.sharingWith">
                <option :value="undefined">-- Select dispatcher --</option>
                <option :value="dispatcher._id" :key="dispatcher._id" v-for="dispatcher of users">{{ dispatcher.name }} {{ dispatcher.disabled ? 'No Longer Active' : '' }}</option>
            </select>
        </div>

        <Input v-model="item.notes" name="Load notes" placeholder="Load notes" :error="errors.notes"/>


        <div class="action">
            <button class="default medium reverse"  @click="$emit('previous')">Previous</button>
            <button class="default medium" @click="validateData">{{ item._id ? 'Confirm & Save' : 'Confirm & Add' }}</button>
        </div>

    </div>
</template>

<script>
import LocationSearch from '../LocationSearch.vue'
import NumberSelector from '../NumberSelector.vue'
import DatePickerSimple from '../../DatePickerSimple.vue'
import SingleFilePicker from '../../SingleFilePicker.vue'
import BrokerSearch from '../BrokerSearch.vue'
import { mapGetters } from 'vuex'

    export default {
        components: {
            LocationSearch,
            NumberSelector,
            DatePickerSimple,
            SingleFilePicker,
            BrokerSearch
        },
        props: ['modelValue'],
        data() {
            return {
                item: this.modelValue || {},
                errors: {},
                brokerContactKey: null,
                brokerContactList: [],
                carrierDrivers: []
            }
        },
        computed: {
            ...mapGetters(['carriers', 'users'])
        },
        methods: {
            getCarrierDrivers() {
                let id = this.item.carrier ? this.item.carrier  : null;
                if(!id) return;


                this.ajax('getCarrierDrivers', {
                    method: 'GET',
                    url: `/carrier/drivers/${id}`,
                }, (err, body) => {
                    if(err) return;
                    if(body) {
                        this.carrierDrivers = body;
                    }
                });

            },
            getBrokerContactDetails() {
                this.brokerContactKey = null;
                let id = this.item.brokerDetails ? this.item.brokerDetails.id  : null;
                if(!id) return;
                this.ajax('getUsersCustomersByRTSId', {
                    method: 'GET',
                    url: `/userCustomers/${id}`,
                }, (err, body) => {
                    if(err) return;
                    if(body) this.brokerContactList = body;
                });
            },
            validateData() {
                this.errors = {};
                this.ajax('validateAndPostLoad', {
                    method: 'POST',
                    url: `/dispatch/validateAndPostLoad`,
                    data: this.item
                }, (err, body) => {

                    if(err) {
                        if(body.errors) {
                            this.errors = body.errors;
                        } else {
                            alert(body.message || 'Information provided is invalid.');
                        }
                        return;
                    } 

                    this.$emit('complete');
                    this.$store.state.loadManager.viewLoad = null;
                    this.$store.state.loadManager.viewLoad = body._id;
                });
                
            }
        },
        mounted() {
            this.getBrokerContactDetails();
            this.getCarrierDrivers();
        },
        watch: {
            modelValue: {
                handler() {
                    this.item = this.modelValue || {};
                }
            },
            item: {
                deep: true,
                handler() {
                    this.$emit('update:modelValue', this.item);
                }
            },
            "item.carrier"() {
                this.getCarrierDrivers();
            },
            brokerContactKey() {
                if(!this.brokerContactList[this.brokerContactKey]) return;

                let contact = this.brokerContactList[this.brokerContactKey];
                this.item.contactName = contact.name;
                this.item.contactPhone = contact.phone;
                this.item.contactEmail = contact.email;
            }
        }
    }
</script>

<style lang="scss" scoped>
.gridHolder {
    display: grid;
    row-gap: 20px;
}

.error {
    color: #D8000C;
    background-color: #FFBABA;
    border-left: 2px solid #D8000C;
    padding: 10px;
    margin-bottom: 10px;
    font-size: 12px;
}

.action {
    display: flex;
    justify-content: flex-end;
    .reverse {
        margin-right: 10px;
    }
}

.rowOfTwo {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    column-gap: 20px;
}

</style>