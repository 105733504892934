const connect = (callback) => {
    this.connection = new WebSocket(process.env.VUE_APP_WS_URL);
    this.connected = false;
    this.keepAliveInterval = null;

    let that = this;
    this.connection.addEventListener('open', () => {
        that.connected = true;
        that.keepAliveInterval = setInterval(() => {
            if(that.connected) send('KeepAlive', { data: 'KeepAlive' });
        }, 5000);
        callback(false, true);
    });

    this.connection.addEventListener('error', () => {
        that.connected = false;
        clearInterval(that.keepAliveInterval);
        callback(true, false);
    });

    this.connection.addEventListener('close', () => {
        that.connected = false;
        clearInterval(that.keepAliveInterval);
        setTimeout(() => {
            connect(callback);
        }, 5000);
    });

    this.connection.addEventListener('message', onMessage);
}


const onMessage = (evt) => {
    if(!this.events) this.events = {};
    let message = JSON.parse(evt.data);

    let callback = this.events[message.event];
    if(callback) callback(message.data);
}

const remove = (event) => {
    delete this.events[event];
}

const on = (event, callback) => {
    // creating events array
    if(!this.events) this.events = {};
    this.events[event] = callback;
}

const send = (event, body) => {
    this.connection.send(JSON.stringify({
        event: event,
        data: body
    }))
}


module.exports = {
    connect,
    on,
    send,
    remove
}