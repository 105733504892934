<template>
    <div>
        <div class="userNav">
            <div class="navBG" @click="hideMenu" :class="{ 'hidden' : !navigation }"></div>
            <div class="navGroup" :class="{ 'active' : navigation }">
                
                <UserMenuHeader @click="$router.push({ path: `/app/profile/${user.username}` }); hideMenu()"/>

                <div class="_main">
                    <div class="_links">
                        <router-link to="/app" @click="hideMenu"><i class="fas fa-tachometer-alt"></i><span>Dashboard</span></router-link>
                        <router-link to="/app/dispatch" @click="hideMenu" v-if="isUserPermitted('loads', 'view')"><i class="fas fa-truck-loading"></i><span>Dispatch</span></router-link>
                        <router-link to="/app/reports" @click="hideMenu" v-if="isUserPermitted('loads', 'view')"><i class="fas fa-dollar-sign"></i><span>Reports</span></router-link>
                        <router-link to="/app/carriers" @click="hideMenu" v-if="isUserPermitted('carriers', 'view')"><i class="fas fa-truck-moving"></i><span>Carriers</span></router-link>
                        <router-link to="/app/users" @click="hideMenu" v-if="isUserPermitted('users', 'view')"><i class="fas fa-user"></i><span>Users</span></router-link>
                        <router-link to="/app/teams" @click="hideMenu" v-if="isUserPermitted('teams', 'manage')"><i class="fas fa-user"></i><span>Teams</span></router-link>
                    </div>

                    <div class="_links">
                        <a href="" @click.prevent="logOut"><i class="fas fa-sign-out-alt"></i><span>Log out</span></a>
                        <div class="darkModeSwitch">Dark mode<CheckSwitch v-model="darkModeOn"/></div>
                    </div>
                </div>

                
            </div>
        </div>

    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import UserMenuHeader from './UserMenuHeader.vue'
    export default {
        components: {
            UserMenuHeader
        },
        computed: {
            ...mapGetters(['user', 'navigation'])
        },
        data() {
            return {
                menuShown: false,
                darkModeOn: false
            }
        },
        methods: {
            hideMenu() {
                this.$store.commit('navigation', false);
            },
            logOut() {
                localStorage.removeItem("token");
                this.$store.commit('token', null);
                this.$store.commit('user', null);
                this.$router.push({ path: '/' });
            },
            checkDarkMode() {
                if(this.darkModeOn) {
                    document.body.classList.add('dark-theme');
                } else {
                    document.body.classList.remove('dark-theme');
                }
            }
        },
        mounted() {
            let darkModeOnOrOff = localStorage.getItem('app-dark-modeon');
            if(darkModeOnOrOff) {
                if(darkModeOnOrOff == 'true') this.darkModeOn = true;
                else this.darkModeOn = false;
            }
            this.checkDarkMode();
        },
        watch: {
            darkModeOn() {
                localStorage.setItem("app-dark-modeon", this.darkModeOn);
                this.checkDarkMode();
            }
        }
    }
</script>

<style lang="scss" scoped>

.darkModeSwitch {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: rgba(255,255,255,0.7);
}

.navBG {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.3);
    opacity: 1;
    pointer-events: initial;
    transition: ease 0.3s;
    z-index: 99999999;
    &.hidden {
        opacity: 0;
        pointer-events: none;
    }
}

.navGroup {
    z-index: 99999999;
    position: fixed;
    top: 0;
    right: 0;
    max-width: 350px;
    width: 100%;
    height: 100%;
    background: $buttonColor;
    transform: translateX(100%);
    transition: ease 0.5s;
    width: 100%;
    padding: 30px;
    display: grid;
    grid-template-rows: auto minmax(0, 1fr);
    row-gap: 50px;
    &.active {
        transform: translateX(0);
    }

    ._main {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }


    ._links {
        display: grid;
        row-gap: 10px;
        a {
            display: grid;
            align-items: center;
            column-gap: 20px;
            grid-template-columns: 20px minmax(0, 1fr);
            padding: 0 20px;
            height: 50px;
            transition: ease 0.6s;
            color: rgba(255,255,255,0.7);
            border-radius: 6px;
            &:hover, &.router-link-exact-active {
                background: rgba(0,0,0,0.1);
                color: #fff;
            }
            
        }
    }


    
}
</style>