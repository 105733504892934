<template>
    <div class="quickEditContentHolder">

        <div class="formHolder">
            <div class="input">
                <div class="_header">
                    <label>Carrier</label>
                    <span class="_error">{{ errors.carrier }}</span>
                </div>
                <select v-model="body.carrier">
                    <option :value="undefined">-- Select carrier --</option>
                    <option :key="key" :value="carrier._id" v-for="(carrier, key) of carriers">{{ carrier.name }}</option>
                </select>            
            </div>

            <div class="input" v-if="body.carrier">
                <div class="_header">
                    <label>Delivery driver</label>
                    <span class="_error">{{ errors.carrierDriver }}</span>
                </div>
                <select v-model="body.carrierDriver">
                    <option :value="null">-- Select driver --</option>
                    <option :key="key" :value="driver._id" v-for="(driver, key) of drivers">{{ driver.name }}</option>
                </select>            
            </div>

            <div class="input" v-if="body.carrier">
                <div class="_header">
                    <label>Pick up driver</label>
                    <span class="_error">{{ errors.carrierPickupDriver }}</span>
                </div>
                <select v-model="body.carrierPickupDriver">
                    <option :value="null">-- Select driver --</option>
                    <option :key="key" :value="driver._id" v-for="(driver, key) of drivers">{{ driver.name }}</option>
                </select>            
            </div>
        </div>

        <div class="actionButtons">
            <button class="default medium" :disabled="isLoading(['HandleQuickEdit'])" @click="handleQuickEdit">{{ isLoading(['HandleQuickEdit']) ? 'Saving...' : 'Save changes' }}</button>
        </div>

    </div>
</template>

<script>
import LoadMultiStopsSelector from '../../LoadMultiStopsSelector.vue'

    export default {
        components: {
            LoadMultiStopsSelector
        },
        props: ['options'],
        data() {
            return {
                body: this.options,
                errors: {},
                carriers: [],
                drivers: []
            }
        },
        mounted() {
            this.getListOfCarriers();
            this.getListOfDrivers();
        },
        watch: {
            "body.carrier"() {
                this.getListOfDrivers();
                this.body.carrierDriver = null;
                this.body.carrierPickupDriver = null;
            }
        },
        methods: {
            getListOfDrivers() {
                this.drivers = [];
                if(!this.body.carrier) return;
                this.ajax('GetListOfDrivers', {
                    method: 'GET',
                    url: `/carrier/drivers/${this.body.carrier}`,
                }, (err, body) => {
                    if(err) return;
                    if(body) {
                        this.drivers = body;
                    }
                });
            },
            getListOfCarriers() {
                this.ajax('GetListOfCarriers', {
                    url: '/dispatchCarriers',
                    method: 'GET'
                }, (err, body) => {
                    if(!err) {
                        this.carriers = body;
                    }
                });
            },
            handleQuickEdit() {
                this.errors = {};
                this.ajax('HandleQuickEdit', {
                    url: `/dispatch/QuickEdit/${this.body._id}/validateCarrierDriver`,
                    method: 'POST',
                    data: this.body
                }, (err, body) => {
                    if(err) {
                        if(body.errors) this.errors = body.errors;
                        else alert(body.message || 'Something went wrong. Please, try again!');
                        return;
                    }
                    this.$hideModalSimple({ changesMade: true });
                });
            }
        },
    }
</script>

<style lang="scss" scoped>
.quickEditContentHolder {
    display: grid;
    row-gap: 30px;
    .actionButtons {
        display: flex;
        justify-content: center;
    }
}

.formHolder {
    display: grid;
    row-gap: 15px;
}
</style>