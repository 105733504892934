<template>
    <div>
        <div class="chatBg" :class="{ 'visibleAnimation pointerEventsOn' : chat }" @click="hideChat"></div>
        <div class="chat" :class="{ 'active' : chat }">
            <div class="chatGroup">
                <AllChats v-show="!selectedChat" @selectChat="selectChat"/>
                <SelectedChat @close="() => { selectedChat = null }" :selectedChat="selectedChat" v-if="selectedChat"/>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

import AllChats from './AllChats.vue'
import SelectedChat from './SelectedChat.vue'

    export default {
        components: {
            AllChats,
            SelectedChat
        },
        data() {
            return {
                selectedChat: null
            }
        },
        async mounted() {
            let permission = await Notification.requestPermission();
        },
        computed: {
            ...mapGetters(['chat', 'numberOfModals'])
        },
        methods: {
            hideChat() {
                this.$store.commit('chat', false);
            },
            selectChat(item) {
                this.selectedChat = item;
            }
        },
        watch: {
            chat() {
                if(this.chat) {
                    this.$store.commit('numberOfModals', this.numberOfModals + 1);
                } else {
                    this.$store.commit('numberOfModals', this.numberOfModals - 1);
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
.chat {
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    max-width: 550px;
    transform: translateX(100%);
    height: 100%;
    background: $bg;
    z-index: 9999;
    border-left: 1px solid $borderColor;
    transition: ease 0.5s;
    &.active {
        transform: translateX(0);
    }
}

.chatBg {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.3);
    z-index: 9999;
    pointer-events: none;
    opacity: 0;
    transition: ease 0.5s;
}

.chatGroup {
    height: 100%;
}

</style>