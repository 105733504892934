<template>
    <div class="dateTimePickerGroup">
        <div class="dateTimeHolder">
            <DatePicker v-model="dateTime.date" class="datePickerButtonHolder" position="left" :simple="true"/>
            <TimePicker v-model="dateTime.from" class="datePickerButtonHolder"/>

            <div class="time2Holder">
                <TimePicker v-model="dateTime.to"  class="datePickerButtonHolder" v-if="dateTime.to"/>
                <button @click="addTime" class="datePickerButtonHolder addTimeBtn" v-else>Add range</button>
                <button class="removeBtn" @click="removeTime2" v-if="dateTime.to"><i class="fas fa-trash-alt"></i></button>
            </div>
        </div>
        <div class="selectedTime" v-if="dateTime.string">Selected: {{ dateTime.string }}</div>
    </div>
</template>

<script>
import moment from 'moment'
import DatePicker from './DatePicke.vue'
import TimePicker from './TimePicker.vue'

    export default {
        components: {
            DatePicker,
            TimePicker
        },
        props: ['modelValue'],
        data() {
            return {
                dateTime: this.modelValue || {}
            }
        },
        methods: {
            removeTime2() {
                delete this.dateTime.to;
            },
            addTime() {
                this.dateTime.to = JSON.parse(JSON.stringify(this.dateTime.from));
            },
            verifyDateAndTime() {
                let temp = {};

                if(!this.dateTime) this.dateTime = {};

                if(!this.dateTime.hasOwnProperty('date')) {
                    this.dateTime.date = moment.utc().format("MM/DD/YYYY");
                } else {
                    this.dateTime.date = moment.utc(this.dateTime.date, "MM/DD/YYYY").format("MM/DD/YYYY");
                }

                if(!this.dateTime.hasOwnProperty('from')) {
                    this.dateTime.from = "12:00";
                }


                this.dateTime.string = this.dateTime.date + " " + this.dateTime.from + (this.dateTime.to ? ` - ${this.dateTime.to}` : '');

                this.emitUpdates();
            },
            emitUpdates() {
                this.$emit('update:modelValue', this.dateTime);
            }
        },
        mounted() {
            this.verifyDateAndTime();
        },
        watch: {
            modelValue() {
                this.dateTime = this.modelValue || {};
                this.verifyDateAndTime();
            },
            dateTime: {
                deep: true,
                handler() {
                    this.verifyDateAndTime();
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
.dateTimePickerGroup {
    .dateTimeHolder {
        height: 34px;
        display: grid;
        grid-template-columns: minmax(0, 1fr) 100px 100px;
        column-gap: 10px;
    }
}

.selectedTime {
    margin-top: 10px;
    font-size: 12px;
    opacity: 0.75;
}

.datePickerButtonHolder {
    border: 1px solid $borderColor;
    border-radius: 5px;
    box-shadow: $boxShadow;
    height: 100%;
    width: 100%;
}

.addTimeBtn {
    background: $buttonColor;
    color: $bgSecondary;
    font-size: 12px;
}

.time2Holder {
    height: 100%;
    position: relative;
    .removeBtn {
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
        padding: 0 10px;
        border: 0;
        border-left: 1px solid $borderColor;
        background: transparent;
        font-size: 10px;
        color: red;
    }
}
</style>