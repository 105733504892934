<template>
    <div class="itemsList">
        <div :key="key" class="item" v-for="(item, key) of itemList">

            <div class="itemName">
                <div class="name">{{ item.type }}</div>
                <div class="buttons">
                    <button @click="moveUp(key)"><i class="fa-solid fa-arrow-up"></i></button>
                    <button @click="moveDown(key)"><i class="fa-solid fa-arrow-down"></i></button>
                    <button class="remove" @click="removeItem(key)" :disabled="itemList.length <= 2"><i class="fa-solid fa-trash"></i></button>
                </div>
            </div>

            <div class="stopTypeTab">
                <button @click="itemList[key].type = 'pick'" :class="{ 'active' : item.type == 'pick' }">pick</button>
                <button @click="itemList[key].type = 'drop'" :class="{ 'active' : item.type == 'drop' }">drop</button>
            </div>

            <div class="input">
                <div class="_header">
                    <label>Date & time</label>
                </div>
                <div class="locationSearch" style="padding: 10px">
                    <DateTimePicker v-model="item.date"/>
                </div>
            </div>

            <div class="input">
                <div class="_header">
                    <label>Address</label>
                </div>
                <div class="locationSearch">
                    <div class="error" v-if="error && error[key]">{{ error[key] }}</div>
                    <LocationSearch v-model="item.value"/>
                </div>
            </div>

            <div class="" v-if="item.type == 'pick'">
                <Input name="Pickup #" placeholder="Pickup #" v-model="item.pickupNumber"/>
            </div>

            <Input name="Comments" placeholder="Comments" v-model="item.comments"/>


            <div class="extraStopBtn"><a href="" @click.prevent="addExtraStop(key)">Add extra stop</a></div>

        </div>
    </div>
</template>

<script>
import LocationSearch from './LocationSearch.vue'
import DateTimePicker from '../DateTimePicker.vue'

    export default {
        components: {
            LocationSearch,
            DateTimePicker
        },
        props: ['modelValue', 'error'],
        data() {
            return {
                itemList: this.modelValue || [
                    { type: 'pick', value: null },
                    { type: 'drop', value: null },
                ]
            }
        },
        computed: {
            
        },
        methods: {
            recalculateKey() {
                let num = {};
                let key = 0;
                this.itemList = this.itemList.filter(item => {

                    if(key == 0) {
                        item.type = 'pick';
                    } else if(key == (this.itemList.length - 1)) {
                        item.type = 'drop';
                    }

                    num[item.type] = num[item.type] || 1;
                    item.key = num[item.type];

                    num[item.type]++;
                    key++;
                    return item;
                });
            },
            moveUp(key) {
                if(key <= 0) return;

                let a = JSON.parse(JSON.stringify(this.itemList[key]));
                let b = JSON.parse(JSON.stringify(this.itemList[key - 1]));

                this.itemList[key] = b;
                this.itemList[key - 1] = a;
            },
            moveDown(key) {
                 if(key >= this.itemList.length - 1) return;

                let a = JSON.parse(JSON.stringify(this.itemList[key]));
                let b = JSON.parse(JSON.stringify(this.itemList[key + 1]));

                this.itemList[key] = b;
                this.itemList[key + 1] = a;
            },
            addExtraStop(index) {
                this.itemList.splice(index + 1, 0, { type: 'drop', value: null });
            },
            removeItem(key) {
                this.itemList.splice(key, 1);
            }
        },
        mounted() {

        },
        watch: {
            modelValue() {

                this.itemList = this.modelValue || [
                    { type: 'pick', value: null },
                    { type: 'drop', value: null },
                ];

            },
            itemList: {
                deep: true,
                handler() {
                    this.itemList[0].type = 'pick';
                    this.$emit('update:modelValue', this.itemList);
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
.itemsList {
    display: grid;
    row-gap: 20px;
}

.item {
    display: grid;
    row-gap: 10px;
}

.itemName {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .name {
        font-weight: bold;
        text-transform: uppercase;
    }
    .buttons {
        display: grid;
        grid-template-columns: 24px 24px 24px;
        column-gap: 5px;
        button {
            width: 24px;
            height: 24px;
            border-radius: 5px;
            border: 0;
            border-bottom: 2px solid $borderColor;
            background: $bgSecondary;
            box-shadow: $boxShadow;
            font-size: 10px;
            color: $text;
            transition: ease 0.3s;
            &.remove {
                color: $error;
            }
            &:hover {
                transform: scale(1.15);
            }
            &:disabled {
                opacity: 0.7;
                cursor: not-allowed;
                &:hover {
                    transform: scale(1);
                }
            }
        }
    }
}

.locationSearch {
    background: $bgSecondary;
    padding: 20px;
    border-radius: $borderRadius;
    box-shadow: $boxShadow;
    border: 1px solid $borderColor;
}

.extraStopBtn {
    display: flex;
    align-items: center;
    justify-content: center;
}


.error {
    color: #D8000C;
    background-color: #FFBABA;
    border-left: 2px solid #D8000C;
    padding: 10px;
    margin-bottom: 10px;
    font-size: 12px;
}

.stopTypeTab {
    display: flex;
    align-items: center;
    button {
        height: 34px;
        padding: 0 10px;
        border-radius: $borderRadius;
        border: 1px solid $borderColor;
        background: transparent;
        margin-right: 10px;
        text-transform: uppercase;
        &.active {
            border: 1px solid $buttonColor;
            color: $buttonColor;
        }
    }
}
</style>