<template>
    <div v-if="user">
        
        <NavBar/>
        <div class="page">
            <router-view></router-view>
        </div>
        <Chat/>

        <!-- New load page -->
        <Modal v-model="loadManager.addLoad" title="Load" :hideButtons="true" confirm="Save" @close="$store.state.loadManager.addLoadDetails = {}">
            <InsertUpdateLoad/>
        </Modal>

        <!-- View load page -->
        <ModalSimple v-model="viewLoadModal" :disablePadding="true" :loading="isLoading(['uploadLoadDocument', 'getLoadDetails'])" @close="() => { $store.state.loadManager.viewLoad = null }" title="Load Details" size="large" :hideButtons="true">
            <ViewLoadDetails/>
        </ModalSimple>


         <!-- View load page -->
        <Modal v-model="viewLoadData.modal" :hideTitle="true">
            <ViewLoad v-if="viewLoadData.modal" :loadID="viewLoadData.load"/>
        </Modal>

        <ModalSimple v-model="quickMessage.modal" title="Quick Message" confirm="Send message" @confirm="sendQuickMessage">
            <Input name="Message" v-model="quickMessageData.body.message" @keyup.enter="sendQuickMessage" placeholder="Write a message..."/>
        </ModalSimple>

        <!-- <ModalSimple v-model="showTelegramModal" confirm="Done" @confirm="() => { showTelegramModal = false }" title="Connect Telegram">
            <div class="telegramGridGroup">
                <div>
                    <p>1. Click the button to open telegram.</p>
                    <p>2. Copy the message and send it to the bot.</p>
                    <p>3. You should receive a message "You're all set up.".</p>
                </div>

                <a href="tg://resolve?domain=FLDNotificationsBot" class="default round">Open in Telegram</a>
                <Input type="text" name="Message" :disabled="true" :value="`/notify ${token}`" :copy="true"/>
            </div>
        </ModalSimple> -->

    </div>
</template>

<script>
import ws from '../helpers/ws'

import Header from '../components/Header';
import NavBar from '../components/NavBar';
import Chat from '../components/chats/Chat';
import ViewLoad from '../components/dispatch/ViewLoad.vue'
import ViewLoadDetails from '../components/dispatch/ViewLoadDetails.vue'
import InsertUpdateLoad from '../components/dispatch/InsertUpdateLoad.vue'

import { mapGetters } from 'vuex';
    export default {
        components: {
            Header,
            NavBar,
            Chat,
            ViewLoad,
            InsertUpdateLoad,
            ViewLoadDetails
        },
        data() {
            return {
                showTelegramModal: false,
                viewLoadModal: false
            }
        },
        computed: {
            ...mapGetters(['token', 'user', 'quickMessage', 'viewLoadStore', 'numberOfModals', 'loadManager']),
            isViewingLoad() {
                if(this.loadManager.viewLoad) return true;
                return false;
            }
        },
        methods: {
            getCarriers() {
                this.$axios.get('/dispatchCarriers')
                .then(data => {
                    this.$store.commit('carriers', data.data);
                });
            },
            getUsers() {
                this.$axios.get('/users')
                .then(data => {
                    this.$store.commit('users', data.data);
                });
            }
        },
        mounted() {
            this.getCarriers();
            this.getUsers();

            if(!this.user.telegramChatID) {
                this.showTelegramModal = true;
            }
            
            ws.connect((err, success) => {
                if(err) return console.log("error");

                ws.send('connect', {
                    token: this.token
                });

                this.$store.commit('webSocket', ws);
            });

        },
        watch: {
            numberOfModals() {
                if(this.numberOfModals > 0) {
                    document.body.classList.add('noScroll');
                } else {
                    document.body.classList.remove('noScroll');
                }
            },
            isViewingLoad() {
                this.viewLoadModal = this.isViewingLoad;
            }
        }
    }
</script>

<style lang="scss">
.page {
    padding: 0 0 50px 0 !important;
}
</style>


<style lang="scss" scoped>
.telegramGridGroup {
    display: grid;
    row-gap: 20px;
}
</style>