<template>
<div class="loadDetailsGroup">

    <GoogleMapsRoutes :stops="load && load.stops ? load.stops : []"/>

    <div class="loadDetailsGrid" v-if="loadID && load">
        <div class="originDestination">


            <div class="locationGroup">
                <div class="name">
                    <div>Load Rate</div>
                    <div><a href="" @click.prevent="quickEdit('rate', { rate: load.rate })" v-if="isPermittedToEdit">Quick edit</a></div>
                </div>
                <div class="loadRate" v-if="quickEditManager.name != 'rate'">${{ load.formattedRate || priceConverter(load.rate) }}</div>
                <div class="editForm" v-else>
                    <Input type="text" v-model="quickEditManager.item.rate" name="Rate" placeholder="Rate" :error="quickEditManager.errors.rate" />
                    <button class="default small" @click="handleQuickEditSave">Save changes</button>
                    <div class="cancellHolder"><a href="" @click.prevent="cancelQuickEdit">Cancel</a></div>
                </div>
            </div>

            <div class="rowOfTwo" v-if="load.distance">
                <div class="locationGroup" v-if="load.distance && load.distance.formatted" style="margin-right: 30px">
                    <div class="name">
                        <div>Distance</div>
                    </div>
                    <div class="value distance">{{ load.distance.formatted }} miles</div>
                </div>

                <div class="locationGroup" v-if="load.distance && load.distance.value && load.rate">
                    <div class="name">
                        <div>Rate per mile</div>
                    </div>
                    <div class="value ratePerMile">${{ (Number(load.rate)/load.distance.value).toFixed(2) }}</div>
                </div>
            </div>

            

            <div class="locationGroup" v-if="load.stops">
                <div class="name">
                    <div>Trip Details</div>
                    <div><a href="" @click.prevent="quickEditStops" v-if="isPermittedToEdit">Quick edit</a></div>
                </div>
                
                <div class="value listOfStops">
                    <div :key="key" v-for="(stop, key) of load.stops" class="stop">
                        <div class="stopType">{{ stop.type }} {{ stop.key }} </div>
                        <div class="stopName">{{ stop.value.name }}</div>
                        <div class="stopAddress">{{ stop.value.addressLine1 }}</div>
                        <div class="stopAddress">{{ stop.value.addressLine2 }}</div>
                        <div class="details">
                            <div v-if="stop.date">{{ stop.date.string }}</div>
                            <div v-if="stop.pickupNumber">PU#: {{ stop.pickupNumber }}</div>
                            <div v-if="stop.comments" class="comments"><strong>Comments:</strong> {{ stop.comments }}</div>
                        </div>
                    </div> 
                </div>
                <div class="copyDispatchHolder"><a href="" @click.prevent="copyDispatch" class="copyDispatchBtn">Copy dispatch</a></div>
            </div>

            <div class="originDestination" style="padding: 0;" v-else>
                <div class="locationGroup">
                    <div class="name">
                        <div>Origin</div>
                    </div>
                    <div class="value">{{ load.origin }}</div>
                </div>

                 <div class="locationGroup">
                    <div class="name">
                        <div>Destination</div>
                    </div>
                    <div class="value">{{ load.destination }}</div>
                </div>
            </div>

            <div class="locationGroup">
                <div class="name">
                    <div>Broker</div>
                </div>
                <div class="value">{{ load.broker }}</div>
            </div>

            <div class="locationGroup">
                <div class="name">Broker name</div>
                <div class="value">{{ load.contactName }}</div>
            </div>

            <div class="locationGroup" v-if="load.contactPhone">
                <div class="name">Broker phone</div>
                <div class="value">{{ load.contactPhone }}</div>
            </div>

            <div class="locationGroup" v-if="load.contactEmail">
                <div class="name">Broker email address</div>
                <div class="value">{{ load.contactEmail }}</div>
            </div>

        </div>

        <div class="otherDetails">

            <div class="locationGroup" v-if="dispatcher">
                <div class="name">Dispatcher</div>
                <div class="value">

                    <div class="dispatcher">
                        <div class="image" :style="`background-image: url('${dispatcher.profileImage}')`"></div>
                        <div class="dispatcherDetails">
                            <div class="dispatcherName">{{ dispatcher.name }}</div>
                            <div class="dispatcherPosition">{{ dispatcher.position }}</div>
                        </div>
                        <LoadShareBtn :load="load"/>
                    </div>

                </div>
            </div>

            <div class="locationGroup" v-if="sharingWith">
                <div class="name">Sharing with</div>
                <div class="value">{{ sharingWith.name }}</div>
            </div>

            <div class="locationGroup">
                <div class="name">
                    <div>Picking up on</div>
                </div>
                <div class="value">{{ pickup }}</div>
            </div>

            <div class="locationGroup">
                <div class="name">
                    <div>Carrier</div>
                    <div><a href="" @click.prevent="quickEditCarrierDrivers" v-if="isPermittedToEdit">Quick edit</a></div>
                </div>
                <div class="value">{{ carriersObj[load.carrier] ? carriersObj[load.carrier].name : 'Unknown' }}</div>
            </div>

            <div class="locationGroup">
                <div class="name">
                    <div>Delivered by</div>
                    <div><a href="" @click.prevent="quickEditCarrierDrivers" v-if="isPermittedToEdit">Quick edit</a></div>
                </div>
                <div class="value">
                    <div class="driver-chat-grid">
                        <SimpleToolTip>
                            <template v-slot:text><a href="" @click.prevent>{{ load.driver ? load.driver.name : 'Not assigned' }}</a></template>
                            <template v-slot:content>
                                <h4>DRIVER DETAILS</h4>
                                <div v-if="load.driver">
                                    <div class="row">
                                        <div class="rowName">Name</div>
                                        <div class="rowVal">{{ load.driver.name }}</div>
                                    </div>
                                    <div class="row">
                                        <div class="rowName">Phone</div>
                                        <div class="rowVal">{{ load.driver.phone }}</div>
                                    </div>
                                    <div class="row" v-if="load.driver.email">
                                        <div class="rowName">Email</div>
                                        <div class="rowVal">{{ load.driver.email }}</div>
                                    </div>

                                    <div class="row" v-if="load.driver.truck">
                                        <div class="rowName">Truck</div>
                                        <div class="rowVal">{{ load.driver.truck.name }}</div>
                                    </div>

                                    <div class="row" v-if="load.driver.truck && load.driver.truck.licensePlate">
                                        <div class="rowName">License Plate</div>
                                        <div class="rowVal">{{ load.driver.truck.licensePlate }}</div>
                                    </div>

                                </div>
                                <div v-else>Nothing found.</div>
                            </template>
                        </SimpleToolTip>

                        <button class="default chatButton" @click.prevent="openLoadChat"><i class="fa-solid fa-comments"></i></button>

                    </div>

                </div>
            </div>

            <div class="locationGroup">
                <div class="name">
                    <div>Picked up by</div>
                    <div><a href="" @click.prevent="quickEditCarrierDrivers" v-if="isPermittedToEdit">Quick edit</a></div>
                </div>
                <div class="value">
                    <SimpleToolTip>
                        <template v-slot:text><a href="" @click.prevent>{{ load.pickupDriver ? load.pickupDriver.name : 'Not assigned' }}</a></template>
                        <template v-slot:content>
                            <h4>DRIVER DETAILS</h4>
                            <div v-if="load.pickupDriver">
                                <div class="row">
                                    <div class="rowName">Name</div>
                                    <div class="rowVal">{{ load.pickupDriver.name }}</div>
                                </div>
                                <div class="row">
                                    <div class="rowName">Phone</div>
                                    <div class="rowVal">{{ load.pickupDriver.phone }}</div>
                                </div>
                                <div class="row" v-if="load.pickupDriver.email">
                                    <div class="rowName">Email</div>
                                    <div class="rowVal">{{ load.pickupDriver.email }}</div>
                                </div>

                                <div class="row" v-if="load.pickupDriver.truck">
                                    <div class="rowName">Truck</div>
                                    <div class="rowVal">{{ load.pickupDriver.truck.name }}</div>
                                </div>

                                <div class="row" v-if="load.pickupDriver.truck && load.pickupDriver.truck.licensePlate">
                                    <div class="rowName">License Plate</div>
                                    <div class="rowVal">{{ load.pickupDriver.truck.licensePlate }}</div>
                                </div>

                            </div>
                            <div v-else>Nothing found.</div>
                        </template>
                    </SimpleToolTip>
                </div>
            </div>


            <div class="locationGroup" style="row-gap: 10px;" v-if="load.rateConfirmation && load.rateConfirmation.path">
                <div class="name">
                    <div>Rate Confirmation</div>
                    <div><a href="" @click.prevent="quickEditRateConfirmation" v-if="isPermittedToEdit">Quick edit</a></div>
                </div>
                <div class="value">
                    <a :href="apiURL + `/dispatch/load/${load._id}/DownloadRateCon`" target="_blank">
                        <div class="rateConfirmation">
                            <div class="icon"><i class="fa-solid fa-file-arrow-down"></i></div>
                            <div class="text">View rate confirmation</div>
                        </div>
                    </a>
                </div>
            </div>


            <div class="locationGroup" style="row-gap: 10px;">
                <div class="name">Load documents</div>
                <div class="value">
                    <LoadFiles :documents="load.docs" :permitted="isPermittedToEdit" @fileUploaded="getLoadDetails" :load="load"/>
                </div>
            </div>

            <div class="locationGroup">
                <div class="name">
                    <div>Notes</div>
                    <div><a href="" @click.prevent="quickEditNotes" v-if="isPermittedToEdit">Quick edit</a></div>
                </div>
                <div class="value" v-if="load.notes">{{ load.notes }}</div>
                <div class="value" v-else>No notes provided.</div>
            </div>


            <div class="locationGroup" v-if="isPermittedToEdit">
                <div class="name">Action</div>
                <div class="value">
                    <div class="actionBar">
                        <button class="btn edit" v-tooltip="`Edit`" @click="edit"><i class="fa-solid fa-pencil"></i></button>
                        <button class="btn delete" v-tooltip="`Delete`" @click="remove"><i class="fa-solid fa-trash-can"></i></button>
                    </div>
                </div>
            </div>

        </div>
        
    </div>
</div>
</template>

<script>
import { mapGetters } from 'vuex'
import moment from 'moment'
import Input from '../Input.vue'
import LocationSearch from './LocationSearch.vue'
import DatePicke from '../DatePicke.vue'
import GMap from './GMap.vue'
import GoogleMapsRoutes from './GoogleMapsRoutes.vue'
import SimpleToolTip from '../SimpleToolTip.vue'
import LoadFiles from './LoadFiles.vue'

import LoadChat from '../LoadsChat/Chat.vue'

import QuickEditOriginDestination from './AddEdit/QuickEdit/OriginDestination.vue'
import QuickEditRateConfirmation from './AddEdit/QuickEdit/RateConfirmation.vue'
import QuickEditCarrierDrivers from './AddEdit/QuickEdit/CarrierDrivers.vue'
import QuckEditLoadNotes from './AddEdit/QuickEdit/LoadNotes.vue'
import LoadShareBtn from './LoadShareBtn.vue'

    export default {
        components: { 
            Input,
            LocationSearch,
            DatePicke,
            GMap,
            SimpleToolTip,
            LoadFiles,
            GoogleMapsRoutes,
            LoadShareBtn
        },
        data() {
            return {
                load: null,
                quickEditManager: {
                    name: null,
                    item: {},
                    errors: {}
                },
                mapLoading: true,
                carrierDrivers: []
            }
        },
        computed: {
            ...mapGetters(['loadManager', 'users', 'carriers', 'user']),
            loadID() {
                return this.loadManager.viewLoad;
            },
            apiURL() {
                return process.env.VUE_APP_API_URL;
            },
            carriersObj() {
                let list = {};
                for(const item of this.carriers) {
                    list[item._id] = item;
                }
                return list;
            },
            dispatchers() {
                let list = {};
                for(const item of this.users) {
                    list[item._id] = item;
                }
                return list;
            },
            dispatcher() {
                return this.dispatchers[this.load.dispatcher];
            },
            dispatcherImage() {
                if(!this.dispatcher.image || !this.dispatcher.image.path) return '';
                return this.apiURL + this.dispatcher.image.path;
            },
            sharingWith() {
                if(!this.load.sharingWith) return null;
                return this.dispatchers[this.load.sharingWith];
            },
            pickup() {
                return moment.utc(this.load.date, "MM/DD/YYYY").format("dddd, D MMM, YYYY");
            },
            isPermittedToEdit() {
                if(
                    ((this.user._id == this.load.dispatcher && this.isUserPermitted("loads", "edit") || this.isUserPermitted("loads", "editAll")) && this.load.completed != true)
                    || this.isUserPermitted("loads", "manageAll")
                ) return true;
                return false;
            },
        },
        methods: {
            openLoadChat() {
                this.$showModalSimple(LoadChat, 'Chat', {
                    loadId: this.load._id
                })
            },
            quickEditStops() {
                this.$showModalSimple(QuickEditOriginDestination, 'Origin & Destination', {
                    stops: this.load.stops,
                    _id: this.load._id
                }, (data) => {
                    if(data && data.changesMade) this.getLoadDetails();
                });
            },
            quickEditRateConfirmation() {
                this.$showModalSimple(QuickEditRateConfirmation, 'Rate Confirmation', {
                    rateConfirmation: this.load.rateConfirmation,
                    _id: this.load._id
                }, (data) => {
                    if(data && data.changesMade) this.getLoadDetails();
                });
            },
            quickEditCarrierDrivers() {
                this.$showModalSimple(QuickEditCarrierDrivers, 'Carrier & Driver', {
                    carrier: this.load.carrier,
                    carrierDriver: this.load.carrierDriver,
                    carrierPickupDriver: this.load.carrierPickupDriver,
                    _id: this.load._id
                }, (data) => {
                    if(data && data.changesMade) this.getLoadDetails();
                });
            },
            quickEditNotes() {
                this.$showModalSimple(QuckEditLoadNotes, 'Load Notes', {
                    notes: this.load.notes,
                    _id: this.load._id
                }, (data) => {
                    if(data && data.changesMade) this.getLoadDetails();
                });
            },
            copyDispatch(e) {
                if(!this.load.stops) return;
                e.target.innerText = 'Copying';

                let text = '';
                let i = 0;
                for(const stop of this.load.stops) {
                    text += `${(stop.type)} ${stop.key}\n\n`;
                    if(stop.type == 'pick' && stop.pickupNumber) text += `PU #: ${stop.pickupNumber}\n\n`;
                    text += `${stop.value.name}\n`;
                    text += `${stop.value.addressLine1}\n`;
                    text += `${stop.value.addressLine2} ${stop.value.zip}\n`;
                    i++;
                    if(this.load.stops[i]) {
                        text += '\n\n\n';
                    }
                }

                text = text.toUpperCase();

                this.copyTextToClipboard(text);
                e.target.innerText = 'Copied';

                setTimeout(() => {
                    e.target.innerText = 'Copy Dispatch';
                }, 1000);
            },
            handleQuickEditSave() {
                this.quickEditManager.errors = {};
                this.ajax('handleQuickEditSave', {
                    method: 'POST',
                    url: `/dispatch/QuickEdit/${this.load._id}/${this.quickEditManager.name}`,
                    data: {
                        ...this.quickEditManager.item,
                        _id: this.loadID
                    }
                }, (err, body) => {
                    if(err) {
                        if(body.errors) this.quickEditManager.errors = body.errors;
                        else alert(body.message || `Something went wrong. Please, try again later!`);
                        return;
                    }
                    this.cancelQuickEdit();
                    this.getLoadDetails();
                });
            },
            cancelQuickEdit() {
                this.quickEditManager.name = null;
                this.quickEditManager.item = {};
                this.quickEditManager.errors = {};
            },
            quickEdit(name, value) {

                if(['deliveryDriver', 'pickupDriver'].includes(name)) {
                    this.carrierDrivers = [];
                    this.ajax('quickEditLoadCarrierDrivers', {
                        method: 'GET',
                        url: `/carrier/drivers/${this.load.carrier}`,
                    }, (err, body) => {
                        if(err) return;
                        if(body) {
                            this.carrierDrivers = body;
                        }
                    });
                } 


                this.quickEditManager.name = name;
                this.quickEditManager.errors = {};
                this.quickEditManager.item = JSON.parse(JSON.stringify(value));
            },
            edit() {
                this.$store.state.loadManager.addLoad = true;
                this.$store.state.loadManager.addLoadDetails = JSON.parse(JSON.stringify(this.load));
                this.$store.state.loadManager.viewLoad = null;
            },
            remove() {
                if(!this.loadID) return;

                let confirmDelete = confirm('Do you really wish to delete this load?');
                if(!confirmDelete) return;
                this.ajax('removeLoad', {
                    method: 'DELETE',
                    url: `/dispatch/load/${this.loadID}`
                }, (err, body) => {
                    if(err) {
                        alert(body.message || `Couldn't delete this load. Please, try again!`);
                        return;
                    }
                    this.$store.state.loadManager.viewLoad = null;
                });
            },
            getLoadDetails() {
                if(!this.loadID) return;
                this.quickEditManager.name = null;
                this.ajax('getLoadDetails', {
                    method: 'GET',
                    url: `/dispatch/load/${this.loadID}`
                }, (err, body) => {
                    if(err) {
                        this.$store.state.loadManager.viewLoad = null;
                        return;
                    }
                    this.load = body;
                });
            }
        },
        watch: {
            loadID() {
                this.getLoadDetails();
            }
        }
    }
</script>

<style lang="scss" scoped>

.driver-chat-grid {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .chatButton {
        width: 34px;
        height: 34px;
        border-radius: 12px;
        padding: 0;
        font-size: 12px;
    }
}

.copyDispatchHolder {
    margin-top: 10px;
    display: flex;
    justify-content: center;
    a {
        text-transform: uppercase;
        font-weight: bold;
        font-size: 12px;
    }
}

.listOfStops {
    
}

.stop {
    position: relative;
    padding: 17px 10px 0 30px;
    text-transform: uppercase;
    font-size: 13px;
    .details {
        margin-top: 10px;
        opacity: 0.75;
        display: grid;
        row-gap: 5px;
        .comments {
            margin-top: 5px;
            text-transform: none;
        }
    }
    &::before {
        position: absolute;
        top: 20px;
        left: 0;
        content: '';
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background: $buttonColor;
    }
    &::after {
        position: absolute;
        top: 35px;
        left: 5px;
        bottom: -15px;
        transform: translateX(-50%);
        content: '';
        width: 1px;
        background: linear-gradient(180deg, $bg 0%, $buttonColor 50%, $bg 100%);
    }
    &:last-child {
        &::after {
            content: none;
        }
    }
    .stopType {
        font-weight: bold;
        text-transform: uppercase;
        margin-bottom: 10px;
    }
}

.actionBar {
    display: grid;
    grid-template-columns: repeat(5, 44px);
    column-gap: 10px;
    .btn {
        width: 44px;
        height: 44px;
        color: $buttonColor;
        background: $bgSecondary;
        border: 0;
        border-radius: 10px;
        box-shadow: $boxShadow;
        font-size: 14px;
        transition: ease 0.3s;
        border: 1px solid $borderColor;
        &.edit {
            background: #F7DC6F;
            color: #3E3E3E;
        }
        &.delete {
            background: #CD6155;
            color: #fff;
        }
        &:hover {
            transform: scale(1.05);
        }
    }
}

.loadDetailsGrid {
    display: grid;
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
    align-items: start;
    padding: 0 30px;
}

.originDestination {
    display: grid;
    row-gap: 30px;
    padding-right: 30px;
}

.locationGroup {
    display: grid;
    row-gap: 10px;
    .name {
        text-transform: uppercase;
        font-weight: bold;
        font-size: 12px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}

.otherDetails {
    border-left: 1px solid $borderColor;
    padding-left: 30px;
    display: grid;
    row-gap: 30px;
}

.loadRate {
    color: #1abc9c;
    font-weight: bold;
    font-size: 26px;
}

.row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 24px;
    .rowName {
        text-transform: uppercase;
        font-weight: bold;
    }
}

.rowGroup {
    display: grid;
    row-gap: 5px;
    .name {
        text-transform: uppercase;
        font-weight: bold;
        font-size: 12px;
    }
}

.rateConfirmation {
    display: flex;
    align-items: center;
    .icon {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 54px;
        height: 54px;
        box-shadow: $boxShadow;
        border-radius: 10px;
        margin-right: 20px;
        font-size: 18px;
        color: $buttonColor;
        background: $bgSecondary;
        border: 1px solid $borderColor;
        transition: ease 0.3s;
    }
    &:hover {
        .icon {
            transform: scale(1.1);
        }
    }
}

.dispatcher {
    height: 44px;
    display: grid;
    grid-template-columns: 44px minmax(0, 1fr) auto;
    column-gap: 10px;
    align-items: center;
    .image {
        width: 44px;
        height: 44px;
        border-radius: 50%;
        background-color: #fff;
        background-position: center;
        background-size: cover;
        border: 2px solid $buttonColor;
    }
    .dispatcherDetails {
        display: grid;
        row-gap: 3px;
        .dispatcherName {
            font-weight: bold;
            font-size: 14px;
            text-transform: uppercase;
        }
        .dispatcherPosition {
            font-size: 12px;
            text-transform: uppercase;
        }
    }
}

.editForm {
    box-shadow: $boxShadow;
    padding: 20px;
    border-radius: 5px;
    background: $bgSecondary;
    display: grid;
    row-gap: 20px;
    .cancellHolder {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 12px;
    }
}

.loadDetailsGroup {
    display: grid;
    row-gap: 30px;
}

.mapHolder {
    width: 100%;
    padding-top: 50%;
    position: relative;
    iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: 0;
    }
    .mapLoading {
        background: $bgSecondary;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        border-bottom: 1px solid $borderColor;
        border-top: 1px solid $borderColor;
        text-transform: uppercase;
        font-weight: bold;
        font-size: 17px;
        opacity: 1;
        transition: ease 0.5s;
        transition-delay: 0.2s;
        &.hidden {
            opacity: 0;
            pointer-events: none;
        }
    }
}

.rowOfTwo {
    display: flex;
    align-items: center;
}

.distance {
    font-weight: bold;
    font-size: 18px;
    color: $buttonColor;
}

.ratePerMile {
    font-weight: bold;
    font-size: 18px;
    color: $buttonColor;
}
</style>