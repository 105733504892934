<template>
    <div class="gridHolder">

        <div>
            <LoadMultiStopsSelector v-model="item.stops" :error="errors.stops"/>
        </div>

        <div class="action">
            <button class="default medium" @click="validateData">Next</button>
        </div>
    </div>
</template>

<script>
import LocationSearch from '../LocationSearch.vue'
import LoadMultiStopsSelector from '../LoadMultiStopsSelector.vue'

    export default {
        components: {
            LocationSearch,
            LoadMultiStopsSelector
        },
        props: ['modelValue'],
        data() {
            return {
                item: this.modelValue || {},
                errors: {}
            }
        },
        methods: {
            validateData() {
                this.errors = {};
                this.ajax('validateOriginDestination', {
                    method: 'POST',
                    url: `/dispatch/validateOriginDestination`,
                    data: {
                        stops: this.item.stops,
                        pickup: this.item.pickup,
                        delivery: this.item.delivery
                    }
                }, (err, body) => {
                    if(err) {
                        if(body.errors) {
                            this.errors = body.errors;
                        } else {
                            alert(body.message || 'Information provided is invalid.');
                        }
                        return;
                    } 
                    this.$emit('next');
                    return;
                });
                
            }
        },
        watch: {
            modelValue: {
                handler() {
                    this.item = this.modelValue || {};
                }
            },
            item: {
                deep: true,
                handler() {
                    this.$emit('update:modelValue', this.item);
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
.gridHolder {
    display: grid;
    row-gap: 20px;
}

.error {
    color: #D8000C;
    background-color: #FFBABA;
    border-left: 2px solid #D8000C;
    padding: 10px;
    margin-bottom: 10px;
    font-size: 12px;
}

.action {
    display: grid;
    justify-items: end;
}
</style>