<template>
    <div class="detailsContentHeightHolder" ref="detailsContentHeightHolder">
        <div class="detailsContent" ref="detailsContent">
            
            <div class="steps" v-if="steps.length > 1" :style="`grid-template-columns: repeat(${steps.length}, minmax(0, 1fr));`">
                <div class="step" :class="{ 'active' : key === step }" :key="key" v-for="(item, key) of steps"></div>
            </div>

            <div class="stepTitle">{{ currentStep.name }}</div>
            <div class="componentHolder">
                <Transition name="bounce">
                    <component :is="currentStep.component" v-model="item" @previous="prevStep" @next="nextStep" @complete="complete"></component>
                </Transition>
            </div>
            
        </div>
    </div>
</template>

<script>
import LocationSearch from './LocationSearch.vue'

import OriginDestination from './AddEdit/OriginDestination.vue'
import LoadDetails from './AddEdit/LoadDetails.vue'
import OtherLoadDetails from './AddEdit/OtherLoadDetails.vue'
import { mapGetters } from 'vuex'


    export default {
        components: {
            LocationSearch,
            OriginDestination,
            LoadDetails,
            OtherLoadDetails
        },
        props: ['load'],
        data() {
            return {
                item: this.load || {},
                steps: [
                    { name: 'Origin & Destination', component: 'OriginDestination' },
                    { name: 'Loads Details', component: 'LoadDetails' },
                    { name: 'Other Load Details', component: 'OtherLoadDetails' }
                ],
                step: 0
            }
        },
        methods: {
            complete(loadID) {
                this.step = 0;
                this.$store.state.loadManager.addLoadDetails = {};
                this.$store.state.loadManager.addLoad = false;
                this.$store.state.loadManager.viewLoad = loadID;
            },
            nextStep() {
                this.step++;
            },
            prevStep() {
                this.step--;
            }
        },
        computed: {
            ...mapGetters(['loadManager']),
            currentStep() {
                return this.steps[this.step];
            }
        },
        mounted() {
            let el = this.$refs['detailsContent'];
            let heightConroller = this.$refs['detailsContentHeightHolder'];
            let height = el.offsetHeight;
            heightConroller.style.height = height + "px";
            
            new ResizeObserver(() => {
                let height = el.offsetHeight;
                heightConroller.style.height = height + "px";
            }).observe(el);
        },
        watch: {
            "loadManager.addLoadDetails"() {
                this.item = this.loadManager.addLoadDetails;
                this.step = 0;
            }
        }
    }
</script>

<style lang="scss" scoped>
.detailsContentHeightHolder {
    transition: ease 0.1s;
    height: 0;
}


.detailsContent {
    display: grid;
    row-gap: 20px;
}

.stepTitle {
    font-size: 16px;
    text-transform: uppercase;
    font-weight: bold;
}

.steps {
    width: 100%;
    display: grid;
    column-gap: 10px;
    
    .step {
        height: 6px;
        border-radius: 3px;
        background: $buttonColor; 
        opacity: 0.2;
        &.active {
            opacity: 1;
        } 
    }
}


.bounce-enter-active {
  animation: bounce-in 0.5s;
}


@keyframes bounce-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>